<template>
    <el-form  v-if="dynamicValidateForm!=null&&dynamicValidateForm.fromValueList.length>0" :model="dynamicValidateForm" ref="dynamicValidateForm" label-width="80px" label-position="top">
        <el-row :gutter="20"> 
            <div v-if="dynamicValidateForm!=null&&dynamicValidateForm.fromValueList.length>0" v-for="(item,index) in dynamicValidateForm.fromValueList"> 
                <el-col :span="item.Type==5||item.Type==6?24:8">
                    <!--文本框-->
                    <el-form-item :label="item.Name" :key="item.Id" :prop="'fromValueList.' + index + '.RecValue'" :rules="setRulesData(item)" v-if="item.Type==1" >
                        <el-input v-model="item.RecValue"></el-input>
                    </el-form-item>
                    <!--整数-->
                    <el-form-item :label="item.Name" :key="item.Id" :prop="'fromValueList.' + index + '.RecValue'" :rules="setRulesData(item)" v-if="item.Type==2" >
                        <el-input v-model.number="item.RecValue"></el-input>
                    </el-form-item>
                    <!--浮点-->
                    <el-form-item :label="item.Name" :key="item.Id" :prop="'fromValueList.' + index + '.RecValue'" :rules="setRulesData(item)" v-if="item.Type==3" >
                        <el-input v-model="item.RecValue" oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
                    </el-form-item>
                    <!--下拉列表-->
                    <el-form-item :label="item.Name" :key="item.Id" :prop="'fromValueList.' + index + '.RecValue'" :rules="setRulesData(item)" v-if="item.Type==4">
                        <el-select style="width:100%" v-model="item.RecValue" placeholder="请选择"> 
                            <el-option :label="item2" :value="item2" :key="item2" v-for="(item2,index) in stringToArr(item.Value)"></el-option> 
                        </el-select>
                    </el-form-item>
                    <!--单选-->
                    <el-form-item :label="item.Name" :key="item.Id" :prop="'fromValueList.' + index + '.RecValue'" :rules="setRulesData(item)" v-if="item.Type==5">
                        <el-radio-group style="width:100%" v-model="item.RecValue">
                           <el-radio :label="item2" :key="item2" v-for="(item2,index) in stringToArr(item.Value)">{{item2}}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <!--多选-->
                    <el-form-item :label="item.Name" :key="item.Id" :prop="'fromValueList.' + index + '.RecValue'" :rules="setRulesData(item)" v-if="item.Type==6">
                        <el-checkbox-group v-model="item.RecValue">
                        <el-checkbox :name="item.Name" :label="item2" :key="index2" v-for="(item2,index2) in stringToArr(item.Value)">{{item2}}</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <!--日期-->
                    <el-form-item :label="item.Name" :key="item.Id" :prop="'fromValueList.' + index + '.RecValue'" :rules="setRulesData(item)" v-if="item.Type==7"> 
                        <el-date-picker type="date" placeholder="选择日期" v-model="item.RecValue" style="width: 100%;"></el-date-picker> 
                    </el-form-item>
                </el-col> 
            </div> 
        </el-row> 
    </el-form> 
</template>
  
<script>  
export default { 
    name:'TypeValueTemp',
    props: { dynamicValidateForm: {
                fromValueList: [], 
            }} ,
    data() {
        return { 
           
        };
    },
    computed: {

    },
    methods: { 
        stringToArr(v){ 
            if(v!=null&&v.length>0){
                var arr = v.split("|");  
                return arr;
            }
            return [];
        },
        setRulesData(item){ 
            var res = [];
            if(!item.IsNull){ 
                res.push({required: true, message: item.Name+'不能为空', trigger: 'blur'}); 
            }else{ 
                res.push({required: false});
            }
            //数字型
            if(item.Type==2){ 
                res.push({ type: 'number', message: item.Name+'必须为数字值'});
            }

            return res;
        },
        submitForm() {  
            return this.$refs["dynamicValidateForm"]; 
        },
    },
    mounted() {
          
    },
};
</script> 