<template>
  <div class="zichanbox_ul">
    <div class="zichanbox_li">
      <!--头部-->
      <div class="zichan_head">
        <div class="namebox">
          <el-tag type="" >抵押</el-tag>
          广元市旺苍县东河镇滨河路水岸华庭3栋201
        </div>
        <div class="zcresultbox">
          司法处置（市场价值：1,189,000.00元、变现价值：594,500.00元、变现税费：50,000.00元、变现净值：544,500.00元）
        </div>
        <div class="iconbox">
          <i style="font-size: 2.4rem;color: #f56c6c;margin-right: 4rem;cursor: pointer;" class="el-icon-delete" @click="delzichan"></i>
          <i :class="this.show ? 'el-icon-caret-top' : 'el-icon-caret-bottom'" style="font-size: 2.4rem; color: #999999; cursor: pointer" @click="zksq"></i>
        </div>
      </div>
      <!--内容-->
      <el-collapse-transition>
        <div class="zichan_body" v-show="show">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"  label-position="top" >
            <el-row :gutter="20">
              <el-col :span="8" >
                <el-form-item label="项目名称" prop="name">
                  <el-input v-model="ruleForm.name" placeholder="请填写项目名称"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8" >
                <el-form-item label="项目名称" prop="name">
                  <el-input v-model="ruleForm.name" placeholder="请填写项目名称"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8" >
                <el-form-item label="项目名称" prop="name">
                  <el-input v-model="ruleForm.name" placeholder="请填写项目名称"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8" >
                <el-form-item label="位置定位" prop="Remark">
                  <el-input
                    placeholder="请输入或选择地址"
                    v-model="ruleForm.dizhi">
                    <i slot="suffix" class="el-input__icon el-icon-map-location"  style="font-size:24px;color:#333333;font-weight: 500;cursor: pointer;" @click="ditutc()"></i>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>


          </el-form>
        </div>
      </el-collapse-transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
      ruleForm: {
        name:'',
      },
      rules: {
        //债权人
        name: [
          { required: true, message: '请输入资产名称', trigger: 'blur' },
        ],
      }
    };
  },
  methods: {
    //地图弹窗
    ditutc(){
      console.log("我是地图弹窗")
    },
    //删除资产
    delzichan() {
      this.$confirm("您确定删除该的文件吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //展开
    zksq() {
      if (this.show == false) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.zichanbox_ul{
  .zichanbox_li {
    background-color: #ffffff;
    box-shadow: 0 0 0.6rem #e0e0e0;
    border-radius: 0.5rem;
    margin-top: 2rem;
    .zichan_head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 6rem;
      padding: 0 1.5rem;
      border-bottom: 1px dotted #e5e5e5;
      
      .namebox {
        font-size: 1.6rem;
        font-weight: 600;
        .diya{
          color: #ffffff;
          display: block;
        }
      }
      .zcresultbox {
        font-size: 1.4rem;
      }
      // .iconbox{}
    }
    .zichan_body {
      padding: 2rem;
    }
  }
}
</style>