<template>
    <div class="mianbox">
        <div class="topbox">
            <div class="topbox_left">
                <i class="el-icon-d-arrow-left" @click="returntop()"></i>
                {{ basicdata.Code }}（{{ basicdata.Name }}）
            </div>
            <div class="topbox_center">
                创建时间:{{ basicdata.CreateTime }}
            </div>
        </div>
        <div class="Basicinforbox">
            <el-descriptions v-if='!editstate'>
                <el-descriptions-item label="原债权人">{{ basicdata.Name }}</el-descriptions-item>
                <el-descriptions-item label="贷款主体">{{ basicdata.LoansMain }}</el-descriptions-item>
                <el-descriptions-item label="原借款本金(元)">{{
                    basicdata.Cost >= 1000 ? basicdata.Cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : basicdata.Cost
                }}</el-descriptions-item>
                <el-descriptions-item label="现贷款本金余额(元)">{{
                    basicdata.Balance >= 1000 ? basicdata.Balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                        ',') : basicdata.Balance }}</el-descriptions-item>
                <el-descriptions-item label="贷款利息(元)">{{
                    basicdata.Interest >= 1000 ? basicdata.Interest.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                        ',') : basicdata.Interest }}</el-descriptions-item>
                <el-descriptions-item label="其它(元)">{{
                    basicdata.DefaultInterest >= 1000 ? basicdata.DefaultInterest.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                        ',') : basicdata.DefaultInterest }}</el-descriptions-item>
                <el-descriptions-item label="抵债资产类型">{{ basicdata.PledgeAssetType }}</el-descriptions-item>
                <el-descriptions-item label="抵债金额(元)" v-if="basicdata.PledgeAssetType == '抵债资产'">{{
                    basicdata.PledgeAssetPrice >= 1000 ? basicdata.PledgeAssetPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                        ',') : basicdata.PledgeAssetPrice }}</el-descriptions-item>
                <el-descriptions-item label="债权总额(元)">{{
                    basicdata.TotalAmount >= 1000 ? basicdata.TotalAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                        ',') : basicdata.TotalAmount }}</el-descriptions-item>
                <el-descriptions-item label="担保分类">{{ basicdata.GuaranteeType }}</el-descriptions-item>
                <el-descriptions-item label="抵押人">{{ basicdata.Hypothecate }}</el-descriptions-item>
                <el-descriptions-item label="质押人">{{ basicdata.Pledger }}</el-descriptions-item>
                <el-descriptions-item label="保证人">{{ basicdata.Guarantor }}</el-descriptions-item>
                <el-descriptions-item label="司法进度">{{ basicdata.Status }}</el-descriptions-item>
                <el-descriptions-item label="抵债资产类型">{{ basicdata.PledgeAssetType }}</el-descriptions-item>
                <el-descriptions-item label="购入价(元)">{{
                    basicdata.BidPrice >= 1000 ? basicdata.BidPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                        ',') : basicdata.BidPrice }}</el-descriptions-item>
                <el-descriptions-item label="优先受偿金额(元)">{{
                    basicdata.CompensationPrice >= 1000 ? basicdata.CompensationPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                        ',') : basicdata.CompensationPrice }}</el-descriptions-item>
                <el-descriptions-item label="购入评估价(元)">{{
                    basicdata.BuyAssessPrice >= 1000 ? basicdata.BuyAssessPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                        ',') : basicdata.BuyAssessPrice }}</el-descriptions-item>
                <el-descriptions-item label="处置价(元)">{{
                    basicdata.ReducedPrice >= 1000 ? basicdata.ReducedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                        ',') : basicdata.ReducedPrice }}</el-descriptions-item>
                <el-descriptions-item label="备注信息">{{ basicdata.Remarks }}</el-descriptions-item>
            </el-descriptions>
            <el-form ref="basicdata" :model="basicdata" label-width="100px" :rules="rulesFu" label-position="top"
                size='medium' v-if='editstate'>
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="原债权人" prop="Name">
                            <el-input v-model="basicdata.Name" placeholder="请填写原债权人"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="贷款主体" prop="LoansMain">
                            <el-input v-model="basicdata.LoansMain" placeholder="请填写贷款主体"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="原借款本金(元)" prop="Cost">
                            <div @mouseover="ShowPriceTxet(1)">
                                <el-tooltip class="item" effect="dark" placement="top">
                                    <div slot="content">{{ costtip }}<br />{{ costtip2 }}</div>
                                    <el-input v-model="basicdata.Cost" placeholder="请填写借款本金" type="number"
                                        oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                                        @input="AdScale(1)"></el-input>
                                </el-tooltip>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="现贷款本金余额(元)" prop="Balance">
                            <div @mouseover="ShowPriceTxet(2)">
                                <el-tooltip class="item" effect="dark" placement="top">
                                    <div slot="content">{{ costtip }}<br />{{ costtip2 }}</div>
                                    <el-input v-model="basicdata.Balance" placeholder="请填写现贷款本金余额" type="number"
                                        oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                                        @input="AdScale(2)">
                                    </el-input>
                                </el-tooltip>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="贷款利息(元)" prop="Interest">
                            <div @mouseover="ShowPriceTxet(3)">
                                <el-tooltip class="item" effect="dark" placement="top">
                                    <div slot="content">{{ costtip }}<br />{{ costtip2 }}</div>
                                    <el-input v-model="basicdata.Interest" placeholder="请填写贷款利息" type="number"
                                        oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                                        @input="AdScale(3)">
                                    </el-input>
                                </el-tooltip>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="其它(元)" prop="DefaultInterest">
                            <div @mouseover="ShowPriceTxet(4)">
                                <el-tooltip class="item" effect="dark" placement="top">
                                    <div slot="content">{{ costtip }}<br />{{ costtip2 }}</div>
                                    <el-input v-model="basicdata.DefaultInterest" placeholder="请填写其它" type="number"
                                        oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                                        @input="AdScale(4)">
                                    </el-input>
                                </el-tooltip>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="抵债资产类型" prop="PledgeAssetType">
                            <el-select v-model="basicdata.PledgeAssetType" filterable placeholder="请选择抵债资产类型"
                                style="width:100%">
                                <el-option v-for="item in pledgeAssetlist" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="basicdata.PledgeAssetType == '抵债资产' || basicdata.PledgeAssetType == 1">
                        <el-form-item label="抵债金额(元)">
                            <div @mouseover="ShowPriceTxet(5)">
                                <el-tooltip class="item" effect="dark" placement="top">
                                    <div slot="content">{{ costtip }}<br />{{ costtip2 }}</div>
                                    <el-input v-model="basicdata.PledgeAssetPrice" placeholder="请填写抵债金额" type="number"
                                        oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                                        @input="AdScale(5)"></el-input>
                                </el-tooltip>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="债权总额(元)" prop="TotalAmount">
                            <el-input v-model="basicdata.TotalAmount" placeholder="请填写债权总额" type="number"
                                @input="UpScale(basicdata.TotalAmount)"
                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="担保分类" prop="GuaranteeType">
                            <el-select v-model="basicdata.GuaranteeTypeArr" multiple filterable allow-create
                                default-first-option placeholder="请选择担保分类" style="width:100%">
                                <el-option v-for="item in guaranteelist" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="抵押人" prop="HypothecateArr">
                            <el-select v-model="basicdata.HypothecateArr" multiple filterable allow-create
                                default-first-option placeholder="请填写抵、质押人,填写一个按回车后填写下一个" style="width:100%">
                                <el-option v-for="item in Pledgelist" :key="item.value" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="质押人" prop="PledgerArr">
                            <el-select v-model="basicdata.PledgerArr" multiple filterable allow-create default-first-option
                                placeholder="请填写抵、质押人,填写一个按回车后填写下一个" style="width:100%">
                                <el-option v-for="item in Pledgelist" :key="item.value" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="保证人" prop="GuarantorArr">
                            <el-select v-model="basicdata.GuarantorArr" multiple filterable allow-create
                                default-first-option placeholder="请填写保证人,填写一个按回车后填写下一个" style="width:100%">
                                <el-option v-for="item in guarantorlist" :key="item.value" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="司法进度" prop="Status">
                            <el-select v-model="basicdata.Status" filterable placeholder="请选择司法进度" style="width:100%">
                                <el-option v-for="item in judiciallist" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="购入价(元)">
                            <div @mouseover="ShowPriceTxet(6)">
                                <el-tooltip class="item" effect="dark" placement="top">
                                    <div slot="content">{{ costtip }}<br />{{ costtip2 }}</div>
                                    <el-input v-model="basicdata.BidPrice" placeholder="请填写购入价" type="number"
                                        oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                                        @input="AdScale(6)">
                                    </el-input>
                                </el-tooltip>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="购入评估价(元)">
                            <div @mouseover="ShowPriceTxet(7)">
                                <el-tooltip class="item" effect="dark" placement="top">
                                    <div slot="content">{{ costtip }}<br />{{ costtip2 }}</div>
                                    <el-input v-model="basicdata.BuyAssessPrice" placeholder="请填写购入评估价" type="number"
                                        oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                                        @input="AdScale(7)">
                                    </el-input>
                                </el-tooltip>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="优先受偿金额(元)">
                            <div @mouseover="ShowPriceTxet(8)">
                                <el-tooltip class="item" effect="dark"
                                    content="优先于本债权的其他法定优先受偿款（包括工程款、应缴纳的税金、优先于本债权的抵押权等其他法定优先受偿款）" placement="bottom">
                                    <el-tooltip class="item" effect="dark" placement="top">
                                        <div slot="content">{{ costtip }}<br />{{ costtip2 }}</div>
                                        <el-input v-model="basicdata.CompensationPrice" placeholder="请填写优先受偿金额"
                                            type="number"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                                            @input="AdScale(8)">
                                        </el-input>
                                    </el-tooltip>
                                </el-tooltip>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="备注信息" prop="Remarks">
                            <el-input v-model="basicdata.Remarks" type="textarea" placeholder="如有其他说明请填写"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div>
                <el-button type="primary" size="small" v-if='!editstate' @click="edit()">编辑</el-button>
                <el-button type="primary" size="small" v-if='editstate' @click="preservation('basicdata')">保存
                </el-button>
                <el-button size="small" v-if='editstate' @click="cancelbj()">取消</el-button>
            </div>
        </div>
        <div class="resultbox">
            <div class="twobox">
                评估市场价值：{{ basicdata.ComputePrice }}万元、变现价值：{{ basicdata.RealizableValue }} 万元、变现税费：{{
                    basicdata.RealizableTax
                }} 万元、清算价值：{{ basicdata.LiquidateValue }} 万元
            </div>

        </div>
        <div class="xinjianbox">
            <div class="xtopbox">
                <div class="xinjian_left">
                    <el-form :inline="true" :model="searchform" class="demo-form-inline" size="medium">
                        <el-form-item label="资产担保情况">
                            <el-select v-model="searchform.source" filterable placeholder="请选择资产担保情况" style="width:100%">
                                <el-option v-for="item in sourcelist" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="getAssetsInfoList">搜索</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="xinjian_right">
                    <el-button type="primary" size="medium" @click="derive()">导出资产信息</el-button>
                    <el-button type="primary" size="medium" @click="newbuild()">新建资产</el-button>
                </div>
            </div>
            <div class="xbodybox">
                <el-table :data="tableData" @selection-change="handleSelectionChange2" border :style="{ width: '100%', 'margin-bottom': '2rem' }"
                     :header-cell-style="{ background: '#f5f7fa' }">
                    <el-table-column type="selection">
                    </el-table-column>
                    <el-table-column type="index" width="50" label="序号">
                    </el-table-column>
                    <el-table-column prop="ComputeMode" label="担保情况" width="130">
                        <template slot-scope="scope">
                            <el-tag type="primary" disable-transitions>
                                {{ scope.row.ComputeModeName }}
                            </el-tag>
                        </template>
                    </el-table-column>

                    <el-table-column v-for="(item, index) in tableList" :key="index" :prop="item.prop"
                        :label="item.label"></el-table-column>
                    <el-table-column fixed="right" label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button @click="handleClick(scope.row, scope.$index)" type="text" size="small">查看
                            </el-button>
                            <el-button @click="edithandleClick(scope.row, scope.$index)" type="text" size="small">编辑
                            </el-button>
                            <el-button type="text" size="small" @click="handleDelete(scope.$index, scope.row)"
                                slot="reference">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="handleCurrentChange" :current-page="paging.currentPage"
                    :page-sizes="[10, 20, 30]" :page-size="paging.size" layout="total, sizes, prev, pager, next, jumper"
                    :total="paging.total">
                </el-pagination>
            </div>
            <!--资产详情弹窗-->
            <el-drawer :title="assetname" :visible.sync="drawer" direction="rtl" size="70%" style="font-size: 18px;"
                custom-class="demo-drawer" :before-close="AssethandleClose">
                <div class="demo-drawer__content">
                    <el-form ref="ziform" :model="ziform" label-width="80px" :rules="zichanrules" class="tczichanbox"
                        label-position="top">
                        <el-row :gutter="20">
                            <el-col :span="8">
                                <el-form-item label="项目名称" prop="name">
                                    <el-input v-model="ziform.name" placeholder="请填写项目名称"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="位置定位" prop="Remark">
                                    <el-input placeholder="请输入或选择地址" v-model="ziform.dizhi">
                                        <i slot="suffix" class="el-input__icon el-icon-map-location"
                                            style="font-size:24px;color:#333333;font-weight: 500;cursor: pointer;"
                                            @click="ditutc()"></i>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="现场图片" prop="Remark">
                                    <el-upload :action="baseapi" list-type="picture-card" :headers="token"
                                        :data="fileimgForm" :on-success="handleAvatarSuccess"
                                        :before-upload="beforeAvatarUpload" :file-list="filedatas">
                                        <i slot="default" class="el-icon-plus"></i>
                                        <div slot="file" slot-scope="{file}">
                                            <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                                            <span class="el-upload-list__item-actions">
                                                <span class="el-upload-list__item-preview"
                                                    @click="handlePictureCardPreview(file)">
                                                    <i class="el-icon-zoom-in"></i>
                                                </span>
                                                <span class="el-upload-list__item-delete" @click="handleDownload(file)">
                                                    <i class="el-icon-download"></i>
                                                </span>
                                                <span class="el-upload-list__item-delete" @click="handleRemove(file)">
                                                    <i class="el-icon-delete"></i>
                                                </span>
                                            </span>
                                        </div>
                                    </el-upload>
                                    <el-dialog :visible.sync="dialogVisible" :append-to-body="true">
                                        <img width="100%" :src="ziform.dialogImageUrl" alt="">
                                    </el-dialog>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="重大瑕疵">
                                    <el-checkbox-group v-model="flawlist">
                                        <el-checkbox v-for="item in zdxc" :label="item" :key="item">{{ item }}</el-checkbox>
                                    </el-checkbox-group>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="现场尽调内容">
                                    <el-input v-model="ziform.Remark" placeholder="现场尽调内容" type="textarea"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="处置价说明">
                                    <el-input v-model="ziform.DisposalPriceRemark" placeholder="处置价说明"
                                        type="textarea"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24" v-if="!clshow">
                                <el-form-item label="评估价补充说明">
                                    <el-input v-model="ziform.NewRemark" placeholder="评估价补充说明" type="textarea"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="处置价(元)">
                                    <div @mouseover="ShowPriceTxet(9)">
                                        <el-tooltip class="item" effect="dark" placement="top">
                                            <div slot="content">{{ costtip }}<br />{{ costtip2 }}</div>
                                            <el-input placeholder="请输入处置价" v-model="ziform.ReducedPrice" type="number"
                                                style="width: 100px;"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                                                @input="AdScale(9)">
                                            </el-input>
                                        </el-tooltip>
                                    </div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="购入评估价(元)">
                                    <div @mouseover="ShowPriceTxet(10)">
                                        <el-tooltip class="item" effect="dark" placement="top">
                                            <div slot="content">{{ costtip }}<br />{{ costtip2 }}</div>
                                            <el-input placeholder="请输入购入评估价" v-model="ziform.BuyAssessPrice" type="number"
                                                style="width: 100px;"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                                                @input="AdScale(10)">
                                            </el-input>
                                        </el-tooltip>
                                    </div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8" v-if="!clshow">
                                <el-form-item label="拟处置初步评估价(元)">
                                    <div @mouseover="ShowPriceTxet(11)">
                                        <el-tooltip class="item" effect="dark" placement="top">
                                            <div slot="content">{{ costtip }}<br />{{ costtip2 }}</div>
                                            <el-input placeholder="请输入拟处置初步评估价" v-model="ziform.NewReducedPrice"
                                                type="number" style="width: 100px;"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                                                @input="AdScale(11)">
                                            </el-input>
                                        </el-tooltip>
                                    </div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="8" v-if="qtzcshow">
                                <el-form-item label="评估市场价值(万元)">
                                    <el-input placeholder="请输入评估市场价值" v-model="ziform.ComputePrice" type="number"
                                        style="width: 100px;"
                                        oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8" v-if="qtzcshow">
                                <el-form-item label="变现价值(万元)">
                                    <el-input placeholder="请输入变现价值" v-model="ziform.RealizableValue" type="number"
                                        style="width: 100px;"
                                        oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8" v-if="qtzcshow">
                                <el-form-item label="变现税费(万元)">
                                    <el-input placeholder="请输入变现税费" v-model="ziform.RealizableTax" type="number"
                                        style="width: 100px;"
                                        oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8" v-if="qtzcshow">
                                <el-form-item label="清算价值(万元)">
                                    <el-input placeholder="请输入清算价值" v-model="ziform.LiquidateValue" type="number"
                                        style="width: 100px;"
                                        oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                    <div v-if="clshow">
                        <TypeValueTemp class="tczichanbox" v-bind:dynamicValidateForm="dynamicValidateForm"
                            ref="typevaluetemp"></TypeValueTemp>
                        <el-form ref="ziform2" :model="ziform2" label-width="80px" :rules="zichanrules2" class="tczichanbox"
                            label-position="top">
                            <el-row :gutter="20">
                                <el-col :span="24">
                                    <el-form-item label="评估面积（㎡）" prop="area">
                                        <el-input type="number" v-model="ziform2.area" autocomplete="off"
                                            style="width: 100px;"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="方法选择" prop="technique">
                                        <el-checkbox-group v-model="ziform2.technique"
                                            @change="revisete(ziform2.technique)">
                                            <el-checkbox v-for="item in techniquelist" :label="item.key" :key="item.key"
                                                @change="((val) => { cklTechnique(val, item.key) })">
                                                {{ item.value }}</el-checkbox>
                                        </el-checkbox-group>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                        <el-form ref="fun6ref" :model="fun6" label-width="80px" class="tczichanbox" label-position="top"
                            v-if="szjbdjshow" :rules="fun6rules">
                            <el-row :gutter="20">
                              
                                <el-col :span="8">
                                    <el-form-item label="商业比例（%）" prop="D5">
                                        <el-input type="number" v-model="fun6.D5" :step="0.01" autocomplete="off"
                                            style="width: 120px;"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="土地面积（㎡）" prop="D6">
                                        <el-input type="number" v-model="fun6.D6" :step="0.01" autocomplete="off"
                                            style="width: 120px;"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="估价期日" prop="D7">
                                        <el-date-picker type="date" placeholder="请填写估价期日" v-model="fun6.D7">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="住宅终止日期" prop="D8">
                                        <el-date-picker type="date" placeholder="请填写商业终止日期" v-model="fun6.D8"
                                            @change="setsydate(fun6.D8, 'fun6.D9')">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="商业终止日期" prop="D9">
                                        <el-date-picker type="date" placeholder="请填写商业终止日期" v-model="fun6.D9">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="容积率" prop="D10">
                                        <el-input type="number" v-model="fun6.D10" :step="0.01" autocomplete="off"
                                            style="width: 120px;"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <!-- <el-col :span="8">
                                    <el-form-item label="区位及个别因素修正系数（住宅）" prop="K2"> 
                                        <el-input-number v-model="fun6.K2" :precision="2" :step="1" placeholder="请填写区位及个别因素修正系数"></el-input-number> 
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="区位及个别因素修正系数（商业）" prop="K3"> 
                                        <el-input-number v-model="fun6.K3" :precision="2" :step="1" placeholder="请填写区位及个别因素修正系数"></el-input-number> 
                                    </el-form-item>
                                </el-col> -->
                                <el-col :span="8">
                                    <el-form-item label="基准地价信息">
                                        <el-switch
                                        v-model="fun6.A2"
                                        active-color="#13ce66"
                                        inactive-color="#ff4949"
                                        active-text="自动"
                                        inactive-text="手动"
                                        @change="baseldinfochange">
                                        </el-switch>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" v-if="baseldshow">
                                    <el-form-item label="基准地价(住宅)" prop="D16">
                                        <el-input type="number" v-model="fun6.D16" :step="0.01" autocomplete="off"
                                            style="width: 120px;"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" v-if="baseldshow">
                                    <el-form-item label="基准地价基准日(住宅)" prop="D17">
                                        <el-date-picker type="date" placeholder="请填写地价基准日" v-model="fun6.D17">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" v-if="baseldshow">
                                    <el-form-item label="基准地价(商业)" prop="D23">
                                        <el-input type="number" v-model="fun6.D23" :step="0.01" autocomplete="off"
                                            style="width: 120px;"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" v-if="baseldshow">
                                    <el-form-item label="基准地价基准日(商业)" prop="D24">
                                        <el-date-picker type="date" placeholder="请填写地价基准日" v-model="fun6.D24">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-divider></el-divider>
                        </el-form>
                        <el-form ref="fun7ref" :model="fun7" label-width="80px" class="tczichanbox" label-position="top"
                            v-if="gyjzdjshow" :rules="fun7rules">
                            <el-row :gutter="20"> 
                                <el-col :span="8">
                                    <el-form-item label="土地面积（㎡）" prop="C5">
                                        <el-input type="number" v-model="fun7.C5" autocomplete="off" style="width: 100px;"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="估价期日" prop="C6">
                                        <el-date-picker type="date" placeholder="请填写估价期日" v-model="fun7.C6">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="工业终止日期" prop="C7">
                                        <el-date-picker type="date" placeholder="请填写工业终止日期" v-model="fun7.C7">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <!-- <el-col :span="8">
                                    <el-form-item label="区位修正系数" prop="I2"> 
                                        <el-input-number v-model="fun7.I2" :precision="2" :step="1" placeholder="请填写区位修正系数"></el-input-number> 
                                    </el-form-item>
                                </el-col> -->
                                <el-col :span="8">
                                    <el-form-item label="基准地价信息">
                                        <el-switch
                                        v-model="fun7.A2"
                                        active-color="#13ce66"
                                        inactive-color="#ff4949"
                                        active-text="自动"
                                        inactive-text="手动"
                                        @change="baseldinfochange">
                                        </el-switch>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" v-if="baseldshow">
                                    <el-form-item label="基准地价" prop="C11">
                                        <el-input type="number" v-model="fun7.C11" :step="0.01" autocomplete="off"
                                            style="width: 120px;"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" v-if="baseldshow">
                                    <el-form-item label="基准地价基准日" prop="C12">
                                        <el-date-picker type="date" placeholder="请填写地价基准日" v-model="fun7.C12">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>

                            </el-row>
                            <el-divider></el-divider>
                        </el-form>
                        <!--房地产比较法-->
                        <el-form ref="fun1ref" :model="fun1" label-width="80px" class="tczichanbox" label-position="top"
                            v-if="fun1show" :rules="fun1rules">
                            <el-row :gutter="20">
                                <el-col :span="8">
                                    <el-form-item label="用途" prop="C4">
                                        <el-select v-model="fun1.C4" placeholder="请选择用途">
                                            <el-option label="住宅" value="住宅"></el-option>
                                            <el-option label="商业" value="商业"></el-option>
                                            <el-option label="办公" value="办公"></el-option>
                                            <el-option label="车位" value="车位"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" class="szscfbox">
                                    <div class="biaotistyle">房地产比较法 <el-button type="primary" plain
                                            @click="anliekaiguan()">选择案例</el-button>
                                    </div>
                                    <ul class="biaotou">
                                        <li class="one">名称</li>
                                        <li class="two">位置名称</li>
                                        <li class="two">用途</li>
                                        <li li class="two">建筑面积（㎡）</li>
                                        <li class="two">交易单价(元/㎡）</li>
                                        <li class="two">交易总价(万元）</li>
                                    </ul>
                                    <ul class="biaoneirong">
                                        <li class="one">案例一</li>
                                        <li class="two">
                                            <el-input v-model="fun1.Name1" placeholder="请填写位置名称"></el-input>
                                        </li>
                                        <li class="two">
                                            <el-select v-model="fun1.D4" placeholder="请选择用途">
                                                <el-option label="住宅" value="住宅"></el-option>
                                                <el-option label="商业" value="商业"></el-option>
                                                <el-option label="办公" value="办公"></el-option>
                                                <el-option label="车位" value="车位"></el-option>
                                                <!-- <el-option label="办公" value="办公"></el-option> 
                                            <el-option label="高档住宅" value="高档住宅"></el-option> 
                                            <el-option label="公寓" value="公寓"></el-option> 
                                            <el-option label="其它" value="其它"></el-option>   -->
                                            </el-select>
                                        </li>
                                        <li class="two">
                                            <el-input type="number" v-model="fun1.D5" :step="0.01" autocomplete="off"
                                                style="width: 100%"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </li>
                                        <li class="two">
                                            <el-input type="number" v-model="fun1.D8" :step="0.01" autocomplete="off"
                                                style="width: 100%"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </li>
                                        <li class="two">
                                            <el-input type="number" v-model="fun1.D6" :step="0.01" autocomplete="off"
                                                style="width: 100%"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </li>
                                        <!-- <li class="two"> 
                                        <el-tooltip class="item" effect="dark" content="修正指数取80-120" placement="top">
                                            <el-input-number size="small" v-model="fun1.C14" :precision="2" :step="1"   placeholder="区域个别因素" style="width:100%">
                                            </el-input-number> 
                                        </el-tooltip> 
                                    </li> -->

                                    </ul>
                                    <ul class="biaoneirong">
                                        <li class="one">案例二</li>
                                        <li class="two">
                                            <el-input v-model="fun1.Name2" placeholder="请填写位置名称"></el-input>
                                        </li>
                                        <li class="two">
                                            <el-select v-model="fun1.E4" placeholder="请选择用途">
                                                <el-option label="住宅" value="住宅"></el-option>
                                                <el-option label="商业" value="商业"></el-option>
                                                <el-option label="办公" value="办公"></el-option>
                                                <el-option label="车位" value="车位"></el-option>
                                            </el-select>
                                        </li>
                                        <li class="two">
                                            <el-input type="number" v-model="fun1.E5" :step="0.01" autocomplete="off"
                                                style="width: 100%"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </li>
                                        <li class="two">
                                            <el-input type="number" v-model="fun1.E8" :step="0.01" autocomplete="off"
                                                style="width: 100%"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </li>
                                        <li class="two">
                                            <el-input type="number" v-model="fun1.E6" :step="0.01" autocomplete="off"
                                                style="width: 100%"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </li>
                                        <!-- <li class="two"> 
                                        <el-tooltip class="item" effect="dark" content="修正指数取80-120" placement="top">
                                            <el-input-number size="small" v-model="fun1.D14" :precision="2" :step="1" style="width:100%">
                                            </el-input-number> 
                                         </el-tooltip> 
                                    </li> -->

                                    </ul>
                                    <ul class="biaoneirong">
                                        <li class="one">案例三</li>
                                        <li class="two">
                                            <el-input v-model="fun1.Name3" placeholder="请填写位置名称"></el-input>
                                        </li>
                                        <li class="two">
                                            <el-select v-model="fun1.F4" placeholder="请选择用途">
                                                <el-option label="住宅" value="住宅"></el-option>
                                                <el-option label="商业" value="商业"></el-option>
                                                <el-option label="办公" value="办公"></el-option>
                                                <el-option label="车位" value="车位"></el-option>
                                            </el-select>
                                        </li>
                                        <li class="two">
                                            <el-input type="number" :step="0.01" v-model="fun1.F5" autocomplete="off"
                                                style="width: 100%"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </li>
                                        <li class="two">
                                            <el-input type="number" v-model="fun1.F8" :step="0.01" autocomplete="off"
                                                style="width: 100%"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </li>
                                        <li class="two">
                                            <el-input type="number" :step="0.01" v-model="fun1.F6" autocomplete="off"
                                                style="width: 100%"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </li>
                                        <!-- <li class="two"> 
                                        <el-tooltip class="item" effect="dark" content="修正指数取80-120" placement="top">
                                        <el-input-number size="small" v-model="fun1.E14" :precision="2" :step="1" style="width:100%">
                                        </el-input-number> 
                                    </el-tooltip> 
                                    </li> -->

                                    </ul>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="特殊情况修正" prop="C17">
                                        <el-tooltip class="item" effect="dark"
                                            content="估价对象与可比实例之间存在较大的差异，例如：估价对象为开发新区，缺乏类似案例，或案例可比性较差，可进行特殊情况修正，修正指数为80-100。"
                                            placement="right">

                                            <el-input type="number" v-model="fun1.C17" :step="0.01" autocomplete="off"
                                                style="width:100px"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </el-tooltip>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-divider></el-divider>
                        </el-form>

                        <el-form ref="fun8ref" :model="fun8" label-width="80px" class="tczichanbox" label-position="top"
                            v-if="fun8show" :rules="fun8rules">
                            <el-row :gutter="20">
                                <el-col :span="8">
                                    <el-form-item label="土地用途" prop="C11">
                                        <el-select v-model="fun8.C11" placeholder="请选择土地用途">
                                            <el-option label="住宅用地" value="住宅用地"></el-option>
                                            <el-option label="商业用地" value="商业用地"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="估价期日" prop="C5">
                                        <el-date-picker type="date" placeholder="请填写估价期日" v-model="fun8.C5">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="宗地面积" prop="C7">
                                        <el-input type="number" v-model="fun8.C7" autocomplete="off" style="width: 100px;"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="容积率" prop="C9">
                                        <el-input type="number" v-model="fun8.C9" autocomplete="off" style="width: 100px;"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="住宅终止日期" prop="B15">
                                        <el-date-picker type="date" placeholder="请填写住宅终止日期" v-model="fun8.B15"
                                            @change="setsydate(fun8.B15, 'fun8.B20')">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="商业终止日期" prop="B20">
                                        <el-date-picker type="date" placeholder="请填写商业终止日期" v-model="fun8.B20">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" class="szscfbox">
                                    <div class="biaotistyle">市场比较法（商住用地） <el-button type="primary" plain
                                            @click="anliekaiguan2(1)">选择案例</el-button>
                                    </div>
                                    <ul class="biaotou">
                                        <li class="one">名称</li>
                                        <li class="two">位置</li>
                                        <li class="two">交易日期</li>
                                        <li class="two">宗地面积（㎡）</li>
                                        <li class="two">容积率</li>
                                        <li class="two">成交价格（元/㎡）</li>
                                        <li class="two">区域个别因素（%）</li>
                                    </ul>
                                    <ul class="biaoneirong">
                                        <li class="one">案例一</li>
                                        <li class="two">
                                            <el-input v-model="fun8.Name1" placeholder="位置"></el-input>
                                        </li>
                                        <li class="two">
                                            <el-date-picker type="date" placeholder="请填写交易日期" v-model="fun8.D5"
                                                style="width:100%">
                                            </el-date-picker>
                                        </li>
                                        <li class="two">
                                            <el-input type="number" v-model="fun8.D7" autocomplete="off"
                                                style="width: 120px;"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </li>
                                        <li class="two">
                                            <el-input type="number" v-model="fun8.D9" autocomplete="off"
                                                style="width: 100px;"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </li>
                                        <li class="two">
                                            <el-input type="number" v-model="fun8.D12" autocomplete="off"
                                                style="width: 100px;"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </li>
                                        <li class="two">
                                            <el-input type="number" v-model="fun8.J30" autocomplete="off"
                                                style="width: 100px;"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </li>
                                    </ul>
                                    <ul class="biaoneirong">
                                        <li class="one">案例二</li>
                                        <li class="two">
                                            <el-input v-model="fun8.Name2" placeholder="位置"></el-input>
                                        </li>
                                        <li class="two">
                                            <el-date-picker type="date" placeholder="请填写交易日期" v-model="fun8.E5"
                                                style="width:100%">
                                            </el-date-picker>
                                        </li>
                                        <li class="two">
                                            <el-input type="number" v-model="fun8.E7" autocomplete="off"
                                                style="width: 120px;"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </li>
                                        <li class="two">
                                            <el-input type="number" v-model="fun8.E9" autocomplete="off"
                                                style="width: 100px;"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </li>
                                        <li class="two">
                                            <el-input type="number" v-model="fun8.E12" autocomplete="off"
                                                style="width: 100px;"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </li>
                                        <li class="two">
                                            <el-input type="number" v-model="fun8.K30" autocomplete="off"
                                                style="width: 100px;"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </li>
                                    </ul>
                                    <ul class="biaoneirong">
                                        <li class="one">案例三</li>
                                        <li class="two">
                                            <el-input v-model="fun8.Name3" placeholder="位置"></el-input>
                                        </li>
                                        <li class="two">
                                            <el-date-picker size="small" type="date" placeholder="请填写交易日期" v-model="fun8.F5"
                                                style="width:100%">
                                            </el-date-picker>
                                        </li>
                                        <li class="two">
                                            <el-input type="number" v-model="fun8.F7" autocomplete="off"
                                                style="width: 120px;"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </li>
                                        <li class="two">
                                            <el-input type="number" v-model="fun8.F9" autocomplete="off"
                                                style="width: 100px;"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </li>
                                        <li class="two">
                                            <el-input type="number" v-model="fun8.F12" autocomplete="off"
                                                style="width: 100px;"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </li>
                                        <li class="two">
                                            <el-input type="number" v-model="fun8.L30" autocomplete="off"
                                                style="width: 100px;"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </li>
                                    </ul>
                                </el-col>
                            </el-row>
                            <el-divider></el-divider>
                        </el-form>
                        <!--工业土地市场法-->
                        <el-form ref="fun9ref" :model="fun9" label-width="80px" class="tczichanbox" label-position="top"
                            v-if="fun9show" :rules="fun9rules">
                            <el-row :gutter="20">
                                <el-col :span="8">
                                    <el-form-item label="估价期日" prop="C5">
                                        <el-date-picker type="date" placeholder="请填写估价期日" v-model="fun9.C5">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="宗地面积" prop="C7">
                                        <el-input type="number" v-model="fun9.C7" autocomplete="off" style="width: 100px;"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="工业终止日期" prop="B16">
                                        <el-date-picker type="date" placeholder="请填写工业终止日期" v-model="fun9.B16">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" class="szscfbox">
                                    <div class="biaotistyle">市场比较法（工业） <el-button type="primary" plain
                                            @click="anliekaiguan2(2)">选择案例</el-button>
                                    </div>
                                    <ul class="biaotou">
                                        <li class="one">名称</li>
                                        <li class="two">位置</li>
                                        <li class="two">交易日期</li>
                                        <li class="two">宗地面积（㎡）</li>
                                        <li class="two">成交价格（元/㎡）</li>
                                        <li class="two">区域个别因素（%）</li>
                                    </ul>
                                    <ul class="biaoneirong">
                                        <li class="one">案例一</li>
                                        <li class="two">
                                            <el-input v-model="fun9.Name1" placeholder="位置"></el-input>
                                        </li>
                                        <li class="two">
                                            <el-date-picker type="date" placeholder="请填写交易日期" v-model="fun9.D5">
                                            </el-date-picker>
                                        </li>
                                        <li class="two">
                                            <el-input type="number" v-model="fun9.D7" autocomplete="off"
                                                style="width: 120px;"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </li>
                                        <li class="two">
                                            <el-input type="number" v-model="fun9.D12" autocomplete="off"
                                                style="width: 100px;"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </li>
                                        <li class="two">
                                            <el-input type="number" v-model="fun9.J30" autocomplete="off"
                                                style="width: 100px;"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </li>
                                    </ul>
                                    <ul class="biaoneirong">
                                        <li class="one">案例二</li>
                                        <li class="two">
                                            <el-input v-model="fun9.Name2" placeholder="位置"></el-input>
                                        </li>
                                        <li class="two">
                                            <el-date-picker type="date" placeholder="请填写交易日期" v-model="fun9.E5">
                                            </el-date-picker>
                                        </li>
                                        <li class="two">
                                            <el-input type="number" v-model="fun9.E7" autocomplete="off"
                                                style="width: 120px;"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </li>
                                        <li class="two">
                                            <el-input type="number" v-model="fun9.E12" autocomplete="off"
                                                style="width: 100px;"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </li>
                                        <li class="two">
                                            <el-input type="number" v-model="fun9.K30" autocomplete="off"
                                                style="width: 100px;"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </li>
                                    </ul>
                                    <ul class="biaoneirong">
                                        <li class="one">案例三</li>
                                        <li class="two">
                                            <el-input v-model="fun9.Name3" placeholder="位置"></el-input>
                                        </li>
                                        <li class="two">
                                            <el-date-picker type="date" placeholder="请填写交易日期" v-model="fun9.F5">
                                            </el-date-picker>
                                        </li>
                                        <li class="two">
                                            <el-input type="number" v-model="fun9.F7" autocomplete="off"
                                                style="width: 120px;"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </li>
                                        <li class="two">
                                            <el-input type="number" v-model="fun9.F12" autocomplete="off"
                                                style="width: 100px;"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </li>
                                        <li class="two">
                                            <el-input type="number" v-model="fun9.L30" autocomplete="off"
                                                style="width: 100px;"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </li>
                                    </ul>
                                </el-col>
                            </el-row>
                            <el-divider></el-divider>
                        </el-form>
                        <!--房产收益法-->
                        <el-form ref="fun2ref" :model="fun2" label-width="80px" class="tczichanbox" label-position="top"
                            v-if="fun2show" :rules="fun2rules">
                            <el-row :gutter="20">
                                <el-col :span="24">
                                    <el-form-item label="是否考虑租约限制">
                                        <el-select v-model="fun2.D1" placeholder="请选择租约限制">
                                            <el-option label="是" value="是"></el-option>
                                            <el-option label="否" value="否"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="价值时点" prop="D3">
                                        <el-date-picker type="date" placeholder="请填写价值时点" v-model="fun2.D3">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="终止日期" prop="D4">
                                        <el-date-picker type="date" placeholder="请填写终止日期" v-model="fun2.D4">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" v-if="fun2.D1 == '否'">
                                    <el-form-item label="租金情况" prop="C6">
                                        <el-select v-model="fun2.C6" placeholder="请选择租金情况">
                                            <el-option label="估价对象实际租金" value="估价对象实际租金"></el-option>
                                            <el-option label="区域内客观租金" value="区域内客观租金"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" v-if="fun2.D1 == '否'">
                                    <el-form-item label="租金（元/㎡·月）" prop="D6">
                                        <el-input type="number" v-model="fun2.D6" :step="0.01" autocomplete="off"
                                            style="width:100px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <div v-if="fun2.D1 == '是'">
                                    <el-col :span="8">
                                        <el-form-item label="客观租金（元/㎡·月）" prop="E6">
                                            <el-input type="number" v-model="fun2.E6" :step="0.01" autocomplete="off"
                                                style="width:100px"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <!-- <el-col :span="8">
                                    <el-form-item label="租赁期内起始日期" prop="F3">
                                        <el-date-picker type="date" placeholder="请填写终止日期" v-model="fun2.F3">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col> -->
                                    <el-col :span="8">
                                        <el-form-item label="租赁期内终止日期" prop="F4">
                                            <el-date-picker type="date" placeholder="请填写终止日期" v-model="fun2.F4">
                                            </el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="租赁期内平均租金（元/㎡·月）" prop="F6">
                                            <el-input type="number" v-model="fun2.F6" :step="0.01" autocomplete="off"
                                                style="width:100px"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>

                                    <!-- <el-col :span="8">
                                    <el-form-item label="租赁期外终止日期" prop="G4">
                                        <el-date-picker type="date" placeholder="请填写终止日期" v-model="fun2.G4">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>  -->
                                </div>
                                <el-col :span="8">
                                    <el-form-item label="报酬率（%）" prop="D23">
                                        <el-tooltip class="item" effect="dark" content="介于5.5%-7%" placement="right">
                                            <el-input type="number" v-model="fun2.D23" :step="0.01" autocomplete="off"
                                                style="width:100px"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </el-tooltip>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item :label="fun2.D1 == '否' ? '收益递增率（%）' : '租赁期外净收益递增率（%）'" prop="D24">
                                        <el-tooltip class="item" effect="dark" content="视经营情况不超过5%" placement="right">
                                            <el-input type="number" v-model="fun2.D24" :step="0.01" autocomplete="off"
                                                style="width:100px"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </el-tooltip>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                        <!--酒店收益法-->
                        <el-form ref="fun3ref" :model="fun3" label-width="80px" class="tczichanbox" label-position="top"
                            v-if="fun3show" :rules="fun3rules">
                            <el-row :gutter="20">
                                <el-col :span="8">
                                    <el-form-item label="酒店星级" prop="B1">
                                        <el-select v-model="fun3.B1" placeholder="请选择酒店星级">
                                            <el-option label="三星级" value="三星级"></el-option>
                                            <el-option label="四星级" value="四星级"></el-option>
                                            <el-option label="五星级" value="五星级"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="建筑面积（㎡）" prop="B2">
                                        <el-input type="number" v-model="fun3.B2" :step="0.01" autocomplete="off"
                                            style="width:100px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="营业情况收集程度" prop="C1">
                                        <el-select v-model="fun3.C1" placeholder="请选择" @change="changeJdType(fun3.C1)">
                                            <el-option label="完整" value="完整"></el-option>
                                            <el-option label="不完整" value="不完整"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="价值时点" prop="B3">
                                        <el-date-picker type="date" placeholder="请填写价值时点" v-model="fun3.B3">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="土地终止日期" prop="B4">
                                        <el-date-picker type="date" placeholder="请填写土地终止日期" v-model="fun3.B4">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" v-if="fun3.C1 == '完整'">
                                    <el-form-item label="客房数量（间）" prop="B10">
                                        <el-input type="number" v-model="fun3.B10" :step="0.01" autocomplete="off"
                                            style="width:100px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>

                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" v-if="fun3.C1 == '完整'">
                                    <el-form-item label="客房平均房价（元/天）" prop="C10">
                                        <el-input type="number" v-model="fun3.C10" :step="0.01" autocomplete="off"
                                            style="width:100px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>

                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" v-if="fun3.C1 == '完整'">
                                    <el-form-item label="客房平均入住率（%）" prop="D10">
                                        <el-input type="number" v-model="fun3.D10" :step="0.01" autocomplete="off"
                                            style="width:100px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>


                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" v-if="fun3.C1 == '完整'">
                                    <el-form-item label="餐厅茶坊座位数（个）" prop="B14">
                                        <el-input type="number" v-model="fun3.B14" :step="0.01" autocomplete="off"
                                            style="width:100px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>


                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" v-if="fun3.C1 == '完整'">
                                    <el-form-item label="餐厅茶坊人均消费（元/人）" prop="C14">
                                        <el-input type="number" v-model="fun3.C14" :step="0.01" autocomplete="off"
                                            style="width:100px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>

                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" v-if="fun3.C1 == '完整'">
                                    <el-form-item label="餐厅茶坊日均上座率（%）" prop="D14">
                                        <el-input type="number" v-model="fun3.D14" :step="0.01" autocomplete="off"
                                            style="width:100px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>


                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" v-if="fun3.C1 == '完整'">
                                    <el-form-item label="会议室间数（间）" prop="B18">
                                        <el-input type="number" v-model="fun3.B18" :step="0.01" autocomplete="off"
                                            style="width:100px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>


                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" v-if="fun3.C1 == '完整'">
                                    <el-form-item label="会议室租金（元/天）" prop="C18">
                                        <el-input type="number" v-model="fun3.C18" :step="0.01" autocomplete="off"
                                            style="width:100px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>


                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" v-if="fun3.C1 == '完整'">
                                    <el-form-item label="会议室日均上座率（%）" prop="D18">
                                        <el-input type="number" v-model="fun3.D18" :step="0.01" autocomplete="off"
                                            style="width:100px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>

                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" v-if="fun3.C1 == '不完整'">
                                    <el-form-item label="项目总收益（万元）">
                                        <el-tooltip class="item" effect="dark"
                                            content="若无法获取到实际经营类型及相关数据，可以根据财务报表数据直接填写经营总收益。" placement="right">
                                            <el-input type="number" v-model="fun3.E21" :step="0.01" autocomplete="off"
                                                style="width:100px"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </el-tooltip>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" v-if="fun3.C1 == '完整'">
                                    <el-form-item label="租金收入（万元）">
                                        <el-input type="number" v-model="fun3.E20" :step="0.01" autocomplete="off"
                                            style="width:100px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>

                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="报酬率（%）">
                                        <el-tooltip class="item" effect="dark" content="5.5%-7%" placement="right">
                                            <el-input type="number" v-model="fun3.B31" :step="0.01" autocomplete="off"
                                                style="width:100px"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </el-tooltip>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="递增率（%）">
                                        <el-tooltip class="item" effect="dark" content="视经营情况取值不超过5%" placement="right">
                                            <el-input type="number" v-model="fun3.B32" :step="0.01" autocomplete="off"
                                                style="width:100px"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </el-tooltip>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-divider></el-divider>
                        </el-form>
                        <!--房地产成本法-->
                        <el-form ref="fun4ref" :model="fun4" label-width="80px" class="tczichanbox" label-position="top"
                            v-if="fun4show" :rules="fun4rules">
                            <el-row :gutter="20">
                                <el-col :span="8">
                                    <el-form-item label="价值时点" prop="B1">
                                        <el-date-picker type="date" placeholder="请填写价值时点" v-model="fun4.B1">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="结构" prop="F13">
                                        <el-select v-model="fun4.F13" placeholder="请选择结构">
                                            <el-option label="钢结构(600-1000)" value="钢结构"></el-option>
                                            <el-option label="钢混结构(1000-2800)" value="钢混结构"></el-option>
                                            <el-option label="砖混结构(700-1500)" value="砖混结构"></el-option>
                                            <el-option label="砖木结构(600-800)" value="砖木结构"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="建安工程费（元/㎡）" prop="G13">
                                        <el-input type="number" v-model="fun4.G13" :step="0.01" autocomplete="off"
                                            style="width:100px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="建成年代" prop="D13">
                                        <el-input v-model.number="fun4.D13" style="width:100px" placeholder="建成年代">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="利润率（%）" prop="C13">
                                        <el-tooltip class="item" effect="dark" content="商业住宅20%-25%,工业8%-15%"
                                            placement="right">
                                            <el-input type="number" v-model="fun4.C13" :step="0.01" autocomplete="off"
                                                style="width:100px"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </el-tooltip>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="观察成新率（%）" prop="E13">
                                        <el-tooltip class="item" effect="dark"
                                            content="完好房：80%-100%，基本完好房：60%-79%，一般损坏房：40%-59%，严重损坏房：39%以下"
                                            placement="bottom">
                                            <el-input type="number" v-model="fun4.E13" :step="0.01" autocomplete="off"
                                                style="width:100px"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </el-tooltip>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="土地使用权" prop="B28">
                                        <el-select v-model="fun4.B28" placeholder="请选择土地使用权"
                                            @change="changeCBType(fun4.B28)">
                                            <el-option label="基准地价" value="基准地价"></el-option>
                                            <el-option label="成本参考价" value="成本参考价"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" v-if="islandlevel">
                                    <el-form-item label="所在级别">
                                        {{ this.fun4.D999 }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="价格（元/㎡）" prop="D28">
                                        <el-input type="number" v-model="fun4.D28" :step="0.01" autocomplete="off"
                                            style="width:100px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                        <el-button v-if="fun4.B28 == '成本参考价'" type="primary" plain @click="tcwinprice()">
                                            成本参考价</el-button>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-divider></el-divider>
                        </el-form>
                        <!--在建工程假设开发法-->
                        <el-form ref="fun5ref" :model="fun5" label-width="80px" class="tczichanbox" label-position="top"
                            v-if="fun5show" :rules="fun5rules">
                            <el-row :gutter="20">
                                <el-col :span="8">
                                    <el-form-item label="价值时点" prop="B1">
                                        <el-date-picker type="date" placeholder="请填写价值时点" v-model="fun5.B1">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="预计竣工日期" prop="B2">
                                        <el-date-picker type="date" placeholder="预计竣工日期" v-model="fun5.B2">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="续建成本内涵" prop="H15">
                                        <el-select v-model="fun5.H15" placeholder="请选择结构">
                                            <el-option label="估价对象续建成本" value="估价对象续建成本"></el-option>
                                            <el-option label="项目整体续建成本" value="项目整体续建成本"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="用途" prop="C15">
                                        <el-select v-model="fun5.C15" placeholder="请选择结构">
                                            <el-option label="普通住宅" value="普通住宅"></el-option>
                                            <el-option label="非普通住宅" value="非普通住宅"></el-option>
                                            <el-option label="非住宅" value="非住宅"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="开发售价（元/㎡）" prop="E15">
                                        <el-input type="number" v-model="fun5.E15" :step="0.01" autocomplete="off"
                                            style="width:100px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="估价对象建安总成本（元/㎡）" prop="F23">
                                        <el-input type="number" v-model="fun5.F23" :step="0.01" autocomplete="off"
                                            style="width:100px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="已投入建安成本（元/㎡）" prop="G23">
                                        <el-input type="number" v-model="fun5.G23" :step="0.01" autocomplete="off"
                                            style="width:100px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>

                            </el-row>
                            <el-divider></el-divider>
                        </el-form>
                        <!--土地剩余法-->
                        <el-form ref="fun10ref" :model="fun10" label-width="80px" class="tczichanbox" label-position="top"
                            v-if="fun10show" :rules="fun10rules">
                            <el-row :gutter="20">
                                <el-col :span="8">
                                    <el-form-item label="评估期日" prop="B3">
                                        <el-date-picker type="date" placeholder="请填写评估期日" v-model="fun10.B3">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="住宅终止日期" prop="B5">
                                        <el-date-picker type="date" placeholder="请填写住宅终止日期" v-model="fun10.B5"
                                            @change="setsydate(fun10.B5, 'fun10.B4')">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="商业终止日期" prop="B4">
                                        <el-date-picker type="date" placeholder="请填写商业终止日期" v-model="fun10.B4">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>


                                <el-col :span="8">
                                    <el-form-item label="开发周期" prop="B8">
                                        <el-tooltip class="item" effect="dark" content="一般为1-3年" placement="top">
                                            <el-input type="number" v-model="fun10.B8" :step="0.01" autocomplete="off"
                                                style="width: 120px"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </el-tooltip>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="商业占比（%）" prop="B21">
                                        <el-input type="number" v-model="fun10.B21" :step="0.01" autocomplete="off"
                                            style="width: 120px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="土地取得成本（万元）" prop="B22">
                                        <el-input type="number" v-model="fun10.B22" :step="0.01" autocomplete="off"
                                            style="width: 120px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="容积率" prop="B23">
                                        <el-input type="number" v-model="fun10.B23" :step="0.01" autocomplete="off"
                                            style="width: 120px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="住宅售价（元/㎡）" prop="B24">
                                        <el-input type="number" v-model="fun10.B24" :step="0.01" autocomplete="off"
                                            style="width: 120px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="商业售价（元/㎡）" prop="B25">
                                        <el-input type="number" v-model="fun10.B25" :step="0.01" autocomplete="off"
                                            style="width: 120px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="办公售价（元/㎡）" prop="B26">
                                        <el-input type="number" v-model="fun10.B26" :step="0.01" autocomplete="off"
                                            style="width: 120px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="酒店式公寓售价（元/㎡）" prop="B27">
                                        <el-input type="number" v-model="fun10.B27" :step="0.01" autocomplete="off"
                                            style="width: 120px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="车位售价（万元/个）" prop="B28">
                                        <el-input type="number" v-model="fun10.B28" :step="0.01" autocomplete="off"
                                            style="width: 120px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="建安成本" prop="B29">

                                        <el-tooltip class="item" effect="dark" content="1300-2800" placement="top">
                                            <el-input type="number" v-model="fun10.B29" :step="0.01" autocomplete="off"
                                                style="width: 120px"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </el-tooltip>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="是否有总平面图" prop="H3">
                                        <el-select v-model="fun10.H3" placeholder="请选择">
                                            <el-option label="是" value="是"></el-option>
                                            <el-option label="否" value="否"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="规划建设净用地面积（㎡）" prop="F4">
                                        <el-input type="number" v-model="fun10.F4" :step="0.01" autocomplete="off"
                                            style="width: 120px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>


                                <el-col :span="8" v-if="fun10.H3 == '是'">
                                    <el-form-item label="住宅用房建筑面积（㎡）" prop="F7">
                                        <el-input type="number" v-model="fun10.F7" :step="0.01" autocomplete="off"
                                            style="width: 120px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" v-if="fun10.H3 == '是'">
                                    <el-form-item label="非住宅用房建筑面积（㎡）" prop="F8">
                                        <el-input type="number" v-model="fun10.F8" :step="0.01" autocomplete="off"
                                            style="width: 120px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" v-if="fun10.H3 == '是'">
                                    <el-form-item label="商业用房建筑面积（㎡）" prop="F9">
                                        <el-input type="number" v-model="fun10.F9" :step="0.01" autocomplete="off"
                                            style="width: 120px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" v-if="fun10.H3 == '是'">
                                    <el-form-item label="办公用房建筑面积（㎡）" prop="F10">
                                        <el-input type="number" v-model="fun10.F10" :step="0.01" autocomplete="off"
                                            style="width: 120px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" v-if="fun10.H3 == '是'">
                                    <el-form-item label="酒店式公寓建筑面积（㎡）" prop="F11">
                                        <el-input type="number" v-model="fun10.F11" :step="0.01" autocomplete="off"
                                            style="width: 120px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" v-if="fun10.H3 == '是'">
                                    <el-form-item label="物管及其他配套用房建筑面积（㎡）" prop="F12">
                                        <el-input type="number" v-model="fun10.F12" :step="0.01" autocomplete="off"
                                            style="width: 120px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>



                                <el-col :span="8" v-if="fun10.H3 == '否'">
                                    <el-form-item label="住宅用房建筑面积比例（%）" prop="H7">
                                        <el-input type="number" v-model="fun10.H7" :step="0.01" autocomplete="off"
                                            style="width: 120px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" v-if="fun10.H3 == '否'">
                                    <el-form-item label="商业用房建筑面积比例（%）" prop="H9">
                                        <el-input type="number" v-model="fun10.H9" :step="0.01" autocomplete="off"
                                            style="width: 120px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" v-if="fun10.H3 == '否'">
                                    <el-form-item label="办公用房建筑面积比例（%）" prop="H10">
                                        <el-input type="number" v-model="fun10.H10" :step="0.01" autocomplete="off"
                                            style="width: 120px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" v-if="fun10.H3 == '否'">
                                    <el-form-item label="酒店式公寓建筑面积比例（%）" prop="H11">
                                        <el-input type="number" v-model="fun10.H11" :step="0.01" autocomplete="off"
                                            style="width: 120px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" v-if="fun10.H3 == '否'">
                                    <el-form-item label="物管及其他配套用房建筑面积比例（%）" prop="H12">
                                        <el-input type="number" v-model="fun10.H12" :step="0.01" autocomplete="off"
                                            style="width: 120px"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-divider></el-divider>
                        </el-form>
                        <el-form ref="ziform2" :model="ziform2" label-width="80px" :rules="zichanrules2" class="tczichanbox"
                            label-position="top">
                            <el-row :gutter="20">
                                <el-col :span="24">
                                    <el-form-item label="变现折扣(%)" prop="discount">
                                        <el-tooltip class="item" effect="dark" :content="discountTip" placement="right">
                                            <el-input type="number" v-model="ziform2.discount" :step="0.01"
                                                autocomplete="off" style="width:100px"
                                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                                            </el-input>
                                        </el-tooltip>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="处置方式" prop="management">
                                        <el-radio-group v-model="ziform2.management" @change="cklManagement">
                                            <el-radio :label="item.key" :key="item.key" v-for="item in managementlist">
                                                {{ item.value }}</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    {{ resPrice }}
                                </el-col>
                            </el-row>
                        </el-form>
                        <div class="resultaerebox">
                            <div class="biaotibox">
                                测算结果
                            </div>
                            <div class="jieguobox">
                                <el-timeline>
                                    <el-timeline-item v-for="(activity, index) in activities" :key="index"
                                        :icon="activity.icon" :type="activity.type" :color="activity.color"
                                        :size="activity.size" :timestamp="activity.timestamp">
                                        {{ activity.content }}
                                    </el-timeline-item>
                                </el-timeline>
                            </div>
                        </div>
                    </div>
                    <div class="demo-drawer__footer">
                        <el-button style="width: 20%;" @click="subAssetData(1)">保存数据</el-button>
                        <el-button v-if="clshow" type="primary" style="width: 20%;" @click="subAssetData(2)">开始测算
                        </el-button>
                        <!-- <el-button @click="cancelForm" style="width: 20%;">保存数据</el-button> -->
                    </div>

                    <!--案例弹窗-->
                    <el-drawer title="案例选择" :append-to-body="true" :visible.sync="anlietc" size="60%"
                        custom-class="demo-drawer">
                        <el-table v-loading="alloading" element-loading-text="拼命加载中"
                            element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)"
                            ref="multipleTable" :data="tbBaseCaseData" tooltip-effect="dark" style="width: 100%"
                            @selection-change="caseSelectionChange">
                            <el-table-column type="selection" width="55">
                            </el-table-column>
                            <el-table-column prop="Name" label="小区名称" width="120">
                            </el-table-column>
                            <el-table-column prop="Location" label="详细位置" show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column prop="Floor" label="楼层" width="120">
                            </el-table-column>
                            <el-table-column prop="Area" label="面积" width="120">
                            </el-table-column>
                            <el-table-column prop="UnitPrice" label="成交单价(元/㎡)" width="130">
                            </el-table-column>
                            <el-table-column prop="Price" label="成交总价(万元)" width="120">
                            </el-table-column>
                            <el-table-column prop="TradeDate" label="交易时间" width="180">
                            </el-table-column>
                            <el-table-column prop="Type" label="类型" width="120">
                            </el-table-column>
                        </el-table>
                        <div
                            style="position: absolute;width: 100%;padding:2rem;box-sizing: border-box;bottom: 0;left:0;background-color: #fff;border-top: 1px solid #DCDFE6;">
                            <el-button type="primary" style=" width: 48%;" @click="addHouseCaseData()">确定</el-button>
                            <el-button style=" width: 48%;" @click="anlietc = false">取 消</el-button>
                        </div>

                    </el-drawer>
                    <!--案例弹窗-->
                    <el-drawer title="案例选择" :append-to-body="true" :visible.sync="anlietc2" size="60%"
                        custom-class="demo-drawer">
                        <el-table ref="multipleTable" :data="tbBaseCaseData2" tooltip-effect="dark" style="width: 100%"
                            @selection-change="caseSelectionChange2">
                            <el-table-column type="selection" width="55">
                            </el-table-column>
                            <el-table-column prop="Name" label="位置" show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column prop="TradeDate" label="交易日期" width="120">
                            </el-table-column>
                            <el-table-column prop="PlotRatio" label="容积率" width="100">
                            </el-table-column>
                            <el-table-column prop="PurposeType" label="用途" width="120">
                            </el-table-column>
                            <el-table-column prop="ServiceYear" label="年限" width="120" show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column prop="Area" label="宗地面积" width="120">
                            </el-table-column>
                            <el-table-column prop="Price" label="成交价格" width="120">
                            </el-table-column>
                            <el-table-column prop="DisPrice" label="折算地价" width="120"  v-if="isDiscountLandPrice">
                            </el-table-column>
                            <el-table-column fixed="right" label="操作" width="120">
                                <template slot-scope="scope">
                                    <el-button @click="handanliClick(scope.row)" type="text" size="small">查看</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div
                            style="position: absolute;width: 100%;padding:2rem;box-sizing: border-box;bottom: 0;left:0;background-color: #fff;border-top: 1px solid #DCDFE6;">
                            <el-button type="primary" style=" width: 48%;" @click="addLandData()">确定</el-button>
                            <el-button style=" width: 48%;" @click="anlietc = false">取 消</el-button>
                        </div>

                        <el-drawer title="数据详情" :append-to-body="true" :visible.sync="detailstc" direction="rtl" size="48%">
                            <div class="miaosubox">
                                <el-descriptions v-for="(item, index) in anlibasicdata" class="margin-top" :column="1"
                                    labelStyle="width:180px;" border>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            {{ item.name }}
                                        </template>
                                        {{ item.value }}
                                    </el-descriptions-item>
                                </el-descriptions>
                            </div>
                        </el-drawer>
                    </el-drawer>
                    <el-drawer title="成本参考价" :append-to-body="true" :visible.sync="winprice" size="50%"
                        custom-class="demo-drawer">
                        <el-table :data="tbpriceData" tooltip-effect="dark" style="width: 100%">
                            <el-table-column prop="Name" label="城市" width="120">
                            </el-table-column>
                            <el-table-column prop="Area" label="地区" show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column prop="Price" label="成交价格" width="120">
                            </el-table-column>
                        </el-table>
                        <div
                            style="position: absolute;width: 100%;padding:2rem;box-sizing: border-box;bottom: 0;left:0;background-color: #fff;border-top: 1px solid #DCDFE6;">
                            <el-button style=" width: 48%;" @click="winprice = false">关闭</el-button>
                        </div>

                    </el-drawer>
                    <!--地图弹窗-->
                    <el-drawer title="地图弹窗" :append-to-body="true" :visible.sync="maptc" size="60%">
                        <div class="mapbox">
                            <div class="saixuan">
                                <div>
                                    <el-cascader ref="areacascader" :props="areaprop" @change="handleChen"
                                        v-model="areavalue" size="medium" placeholder="请选择区域"></el-cascader>
                                </div>
                                <div class="saixuan_left">
                                    <el-input placeholder="请输入详细地址" v-model="poikey" class="input" size="medium" clearable>
                                    </el-input>
                                </div>
                                <div class="saixuan_right">
                                    <el-button type="primary" size="medium" @click="searchzhi()">搜索</el-button>
                                    <el-button type="primary" size="medium" @click="addPoint()">定位</el-button>
                                    <el-button type="primary" size="medium" @click="subPoint()">确定</el-button>
                                </div>

                            </div>
                            <div style=" width: 100%;height: 100%;" id="mapView"></div>
                        </div>
                    </el-drawer>
                </div>
            </el-drawer>
            <!--新建资产弹窗-->
            <el-dialog :title="assettctitle" :visible.sync="dialogFormVisible" width="30%" :before-close="AssethandleClose">
                <el-form ref="newform" :model="newform" label-width="150px" :rules="rulesnow">
                    <el-form-item label="资产名称" prop="name">
                        <el-input v-model="newform.name" placeholder="请填写资产名称"></el-input>
                    </el-form-item>
                    <el-form-item label="资产类型" prop="type">
                        <el-select v-model="newform.type" filterable placeholder="请选择资产类型" style="width:100%"
                            @change="changeAssettype(newform.type)">
                            <el-option v-for="item in typelist" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="担保情况" prop="mortgage">
                        <el-select v-model="newform.mortgage" filterable placeholder="请选择担保情况" style="width:100%"
                            @change="changemortgage(newform.mortgage)">
                            <el-option v-for="item in mortgagelist" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="资产所属人" prop="owner">
                        <el-select v-model="newform.owner" filterable clearable placeholder="请选择资产所属人" multiple style="width:100%">
                            <el-option v-for="item in ownerlist" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="其他资产所属人" prop="CustomersName" v-if="!qtshow">
                        <el-input v-model="newform.CustomersName" placeholder="请填写其他资产所属人"></el-input>
                    </el-form-item>
                    <el-form-item label="资产负债情况" prop="liabilities" v-if="liashow">
                        <el-select v-model="newform.liabilities" filterable placeholder="请选择资产负债情况" style="width:100%"
                            @change="changeLiabilities(newform.liabilities)">
                            <el-option label="负债或或有负债" value="负债或或有负债"></el-option>
                            <el-option label="未知" value="未知"></el-option>
                        </el-select>
                    </el-form-item>
                    <!--资产优先受偿金额-->
                    <el-form-item label="资产优先受偿金额" prop="liabilitiesPrice" v-if="liashow2">
                        <el-input type="number" v-model="newform.liabilitiesPrice" autocomplete="off" style="width: 100px;"
                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                        </el-input>（元）
                    </el-form-item>
                    <!--同级受偿比例为百分比-->
                    <el-form-item label="同级可受偿比例" prop="compensationRatio" v-if="liashow2">
                        <el-input type="number" v-model="newform.compensationRatio" autocomplete="off" style="width: 100px;"
                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                        </el-input>（%）
                    </el-form-item>

                    <el-form-item :label="atype1name" prop="area" v-if="!docshow">
                        <el-input type="number" v-model="newform.area" autocomplete="off" style="width: 100px;"
                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                        </el-input>（㎡）
                    </el-form-item>
                    <!--工业在建工程-->
                    <el-form-item v-if="atype1show" label="土地使用权面积" prop="area2">
                        <el-input type="number" v-model="newform.area2" autocomplete="off" style="width: 100px;"
                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                        </el-input>（㎡）
                    </el-form-item>
                    <el-form-item v-if="cfgyshow" label="总楼层">
                        <el-input type="number" v-model="newform.floor" autocomplete="off" style="width: 100px;"
                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="下载清单" v-if="docshow">
                        <router-link to="" @click.native="gotodocurl()">{{ docname }}</router-link>
                    </el-form-item>
                    <el-form-item label="重要提示" v-if="asstipshow">
                        {{ assetTip }}
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="cancel()">取 消</el-button>
                    <el-button type="primary" @click="determine('newform')">确 定</el-button>
                </div>
            </el-dialog>
        </div>

    </div>
</template> 
 
<script>
import { mapState, mapMutations } from 'vuex';
import assets from '@/components/assets'
import {
    instance, getAssetTypeListPage, getCustomersByFamily, addAssetsInfo, editAssetsInfo,
    getAssetTypeValueByAsset, BaseApiUrl, getFilesTableList, removeFilesTable,
    getComputeTypeByAssetType, editAssetCompute, AssetComputeApi, getAssetLog,
    getNearSphereLand, getNearSphereHouse, addSysOperateLog, getBaseLandPriceValue,
    getBaseLandValue
} from "../../../apis/apis.js";
import TypeValueTemp from "@/components/TypeValueTemp";
import TMap from '../../../../util/initTxMap'
import { changeToChinese } from '../../../../util/helps';
import { gcj_wgs_encrypts2 } from '../../../../util/map_help'

export default {
    components: {
        assets,
        TypeValueTemp,
    },
    data() {
        return {
            flawlist: [],
            zdxc: ['债务人及保证人配合意愿差', '抵押前签订长期租赁协议', '抵押后签字长期租赁协议', '租金存在提前收取情况',
                '存在较大腾退风险', '标的物存在其他法定优先受偿款项或优先债权人', '存在前序抵押或查封', '标的物界址点不清晰',
                '资产的处置受到政府部门或相关职能部门政策影响', '产权及相关资料不完整', '房屋存在私自改扩建或者灭失',
                '项目存在自持或配建要求', '项目存在相关产业限制', '标的物无法独立使用,独立使用性差', '标的物需整体转让 ,无法分割转让',
                '标的物专用性强,通用性差', '标的物存在违规建设或超容的问题', '土地用途及性质与合同不符', '存在未披露债务或已有而未决债务',
                '资产存在其他瑕疵', '抵押人不配合现场查勘', '实际租金明显低于市场租金', '其他'],
            assettctitle: '新建资产',
            asstipshow: false,
            assetTip: '',
            islandlevel: false,
            landlevelname: '',
            discountTip: "",
            detailstc: false,
            anlibasicdata: [],
            cfgyshow: false,
            liashow: false,
            liashow2: false,
            alloading: true,
            qtshow: true,
            urls: "https://api.scdamc-gz.com/wwwroot/doc/股权资料清单.docx",
            docshow: false,
            docname: "股权资料清单.docx",
            clshow: true,
            qtzcshow: true,
            atype1show: false,
            atype1name: '评估面积',
            anlishow: false,
            szjbdjshow: false,
            gyjzdjshow: false,
            fun1show: false,
            fun2show: false,
            fun3show: false,
            fun4show: false,
            fun5show: false,
            fun6show: false,
            fun7show: false,
            fun8show: false,
            fun9show: false,
            fun10show: false,
            fun1: {
                C4: "住宅", C5: "0", C17: "100",
                D4: "", D5: "0", D6: "0", C14: "100", D8: "0",
                E4: "", E5: "0", E6: "0", D14: "100", E8: "0",
                F4: "", F5: "0", F6: "0", E14: "100", F8: "0", Name1: "无", Name2: "无", Name3: "无"
            },
            fun6: { D5: "0", D6: "0", D7: "", D8: "", D9: "", D10: "0", K2: "0", K3: "0", D15: "2020-1-1",A2:true,D16:"0",D17:"",D23:"0",D24:"" },
            fun7: { C5: "0", C6: "", C7: "", C12: "2020-1-1", I2: "",A2:true,C11:"0",C12:"" },
            fun2: { D1: "否", C6: "估价对象实际租金", D3: "", D4: "", D6: "0", E6: "0", F3: "", F4: "", F6: "", G4: "", D23: "0", D24: "0" },
            fun3: { B1: "三星级", B2: "0", B3: "", B4: "", B10: "0", C10: "0", D10: "0", B14: "0", C14: "0", D14: "0", B18: "0", C18: "0", D18: "0", E21: "0", C1: "完整", E20: "0", B31: "0", B32: "0" },
            fun4: { B1: "", C13: "0", D13: "0", E13: "0", F13: "钢结构", G13: "0", B28: "", D28: "0", D999: "" },
            fun5: { B1: "", B2: "", C15: "普通住宅", E15: "0", G15: "0", H15: "估价对象续建成本", F23: "0", G23: "0" },
            fun8: {
                C5: "", C7: "0", C9: "0", C11: "住宅用地", B15: "", B20: "",
                D5: "", D7: "0", D9: "0", D12: "0", J30: "0",
                E5: "", E7: "0", E9: "0", E12: "0", K30: "0",
                F5: "", F7: "0", F9: "0", F12: "0", L30: "0", Name1: "无", Name2: "无", Name3: "无"
            },
            fun9: {
                C5: "", C7: "0", B16: "",
                D5: "", D7: "0", D12: "0", J30: "0", D9: "",
                E5: "", E7: "0", E12: "0", K30: "0", E9: "",
                F5: "", F7: "0", F12: "0", L30: "0", F9: "",
                Name1: "无", Name2: "无", Name3: "无"
            },
            fun10: {
                B3: "", B4: "", B5: "", B8: "0", B21: "0", B22: "0", B23: "0", B24: "0", B25: "0", B26: "0", B27: "0", B28: "0", B29: "0", F4: "0", H7: "0"
                , H9: "0", H10: "0", H11: "0", H12: "0", H3: "是", F7: "0", F8: "0", F9: "0", F10: "0", F11: "0", F12: "0",
            },
            fun1rules: {
                C4: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
                C5: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
                C17: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
            },
            fun2rules: {
                D1: [
                    { required: true, message: '请选择租约限制', trigger: 'change' }
                ],
                D3: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
                D4: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
                D6: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
                E4: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
                E6: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
                F4: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
                F6: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
            },
            fun3rules: {
                B3: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
                B4: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
            },
            fun4rules: {
                B1: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
            },
            fun5rules: {
                B1: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
                B2: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
            },
            fun6rules: {
                D7: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
                D8: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
                D9: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
                D16: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
                D17: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
                D23: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
                D24: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
            },
            fun7rules: {
                C6: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
                C7: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
                C11: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
                C12: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
            },
            fun8rules: {
                C5: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
                B15: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
                B20: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
            },
            fun9rules: {
                C5: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
                B16: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
            },
            fun10rules: {
                B3: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
                B4: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
                B5: [
                    { required: true, message: '请输入值', trigger: 'blur' },
                ],
            },
            tbpriceData: [{ Name: "成都市", Area: "锦江区、青羊区、金牛区、武侯区、成华区、高新南区", Price: "1500元/㎡" },
            { Name: "成都市", Area: "高新西区、天府新区、温江区、双流、郫都区", Price: "675元/㎡" },
            { Name: "成都市", Area: "高新东区、简阳市、彭州市、邛崃市、金堂县、新津县、大邑县、蒲江县", Price: "240元/㎡" },
            { Name: "成都市", Area: "龙泉驿区、新都区", Price: "600元/㎡" },
            { Name: "成都市", Area: "青白江区", Price: "480元/㎡" },
            { Name: "成都市", Area: "都江堰市、崇州市", Price: "270元/㎡" },
            { Name: "地级市", Area: "地级市主城区（甘阿凉除外）", Price: "450元/㎡" },
            { Name: "地级市", Area: "地级市县城", Price: "300元/㎡" },
            { Name: "地级市", Area: "其他区域", Price: "120元/㎡" },
            { Name: "甘阿凉", Area: "主城区", Price: "300元/㎡" },
            { Name: "甘阿凉", Area: "县城", Price: "180元/㎡" },
            { Name: "甘阿凉", Area: "其他区域", Price: "90元/㎡" },],
            tbBaseCaseData: [],
            tbBaseCaseData2: [],
            baseCaseSelection: [],
            baseCaseSelection2: [],
            resPrice: "",
            resultArr: [],//接受验证返回结果数组
            formArr: [],
            searchshow: false,
            map: null,
            markers: [],
            dynamicValidateForm: {
                fromValueList: [],
            },
            activities: [
            ],
            anliform: {
                name: '',
                region: '',
            },
            techniquelist: [],
            managementlist: [{ key: 2, value: '司法处置' }, { key: 3, value: '以物抵债' }],
            ziform: {
                name: '',
                Remark: '',
                NewRemark: '',
                DisposalPriceRemark: '',
                dialogImageUrl: '',
                dizhi: '',
                ReducedPrice: 0,
                NewReducedPrice: 0,
                ComputePrice: 0,
                RealizableValue: 0,
                LiquidateValue: 0,
                RealizableTax: 0,
                Floor: 0,
                Flaw: '',
                Remark: '',
                BuyAssessPrice: 0,
            },
            ziform2: {
                technique: [],
                multipleSelection: [],
                management: "",
                discount: 52,
                area: 0,
            },
            zichanrules: {
                //原债权人
                name: [
                    { required: true, message: '请输入资产名称', trigger: 'blur' },
                ],
            },
            zichanrules2: {
                technique: [
                    { type: 'array', required: true, message: '请至少选择一种测算方法', trigger: 'change' }
                ],
                multipleSelection: [
                    { type: 'array', required: true, message: '请至少选择一个案例', trigger: 'change' }
                ],
                management: [
                    { required: true, message: '请选择处置方式', trigger: 'change' }
                ],
                discount: [
                    { required: true, message: '请输入变现折扣', trigger: 'blur' },
                ],
                area: [
                    { required: true, message: '请输入评估面积', trigger: 'blur' },
                ]
            },
            anlirules: {
                name: [
                    { required: true, message: '请输入资产名称', trigger: 'blur' },
                ],
            },
            //图片上传
            dialogImageUrl: '',
            dialogVisible: false,
            //抽屉的开关
            drawer: false,
            maptc: false,
            anlietc: false,
            anlietc2: false,
            //分页
            paging: {
                currentPage: 1,//当前加载页
                size: 20,//每页显示条目个数
                total: 0,//总条目数
            },
            //表单数据
            tableData: [],
            //抵债资产类型
            pledgeAssetlist: [
                {
                    value: '1',
                    label: '抵债资产'
                }, {
                    value: '2',
                    label: '非抵债资产'
                },
            ],
            //列表展示的值
            tableList: [
                {
                    prop: 'Name',
                    label: '名称'
                },
                {
                    prop: 'TypeName',
                    label: '资产类型'
                },
                {
                    prop: 'DisposeTypeName',
                    label: '处置方式'
                },
                {
                    prop: 'ComputePrice',
                    label: '评估市场价值(万元)'
                },
                {
                    prop: 'RealizableValue',
                    label: '变现价值(万元)'
                },
                {
                    prop: 'RealizableTax',
                    label: '变现税费(万元)'
                },
                {
                    prop: 'LiquidateValue',
                    label: '清算价值(万元)'
                },
            ],
            //新建弹窗
            dialogFormVisible: false,
            newform: {
                id: 0,
                name: '',//资产名称
                type: '',//资产类型
                mortgage: '',//抵押情况
                owner: '',//资产所属人Id
                CustomersName: "",//资产所属人
                area: 0,
                area2: 0,
                floor: 0,
                liabilities: '负债或或有负债',
                liabilitiesPrice: 0,
                compensationRatio: 0,
            },
            rulesnow: {
                //原债权人
                name: [
                    { required: true, message: '请输入资产名称', trigger: 'blur' },
                ],
                type: [
                    { required: true, message: '请选择资产类型', trigger: 'blur' },
                ],
                mortgage: [
                    { required: true, message: '请选择抵押情况', trigger: 'blur' },
                ],
                owner: [
                    { required: true, message: '请选择资产所属人', trigger: 'blur' },
                ],
                area: [
                    { required: true, message: '请输入评估面积', trigger: 'blur' },
                ],
                liabilitiesPrice: [
                    { required: true, message: '请输入资产优先受偿金额', trigger: 'blur' },
                ],
                compensationRatio: [
                    { required: true, message: '请输入同级可受偿比例', trigger: 'blur' },
                ],
            },
            //资产类型列表
            typelist: [
                {
                    value: '1',
                    label: '房产-住宅'
                }, {
                    value: '2',
                    label: '房产-商业'
                }, {
                    value: '3',
                    label: '房产-办公'
                }, {
                    value: '4',
                    label: '房产-车位'
                }, {
                    value: '1002',
                    label: '房产-工业'
                }, {
                    value: '1003',
                    label: '房产-在建工程'
                }, {
                    value: '1004',
                    label: '土地-住宅'
                }, {
                    value: '1005',
                    label: '土地-工业'
                }, {
                    value: '1006',
                    label: '股权'
                }, {
                    value: '1007',
                    label: '采矿权'
                }, {
                    value: '1008',
                    label: '原酒'
                }, {
                    value: '1009',
                    label: '林权'
                }, {
                    value: '1010',
                    label: '房产-酒店'
                }, {
                    value: '1011',
                    label: '土地-商业'
                }, {
                    value: '1012',
                    label: '股票'
                }, {
                    value: '1013',
                    label: '专利'
                }
            ],
            //抵押情况列表
            mortgagelist: [
                {
                    value: 1,
                    label: '抵押资产'
                }, {
                    value: 2,
                    label: '质押资产'
                }, {
                    value: 3,
                    label: '保证资产'
                }, {
                    value: 4,
                    label: '其它可偿债资产'
                }
            ],
            //资产所属人列表
            ownerlist: [],
            //资产来源列表
            sourcelist: [
                {
                    value: null,
                    label: '不限'
                }, {
                    value: 1,
                    label: '抵押资产'
                }, {
                    value: 2,
                    label: '质押资产'
                }, {
                    value: 3,
                    label: '保证资产'
                }, {
                    value: 4,
                    label: '其它可偿债资产'
                }
            ],
            //资产所属主体列表
            Belongslist: [
                {
                    value: '0',
                    label: '不限'
                }, {
                    value: '1',
                    label: '贷款主体'
                }, {
                    value: '2',
                    label: '抵质押人'
                }, {
                    value: '3',
                    label: '保证人'
                },
            ],
            //搜索数值
            searchform: {
                source: '',
                Belongs: '',
            },
            //设置参数
            formInline: {
                Years: 2.5,
                broken: 10,
                Sequence: '0',
            },//参数
            //顺位列表
            Sequencelist: [
                {
                    value: '0',
                    label: '第一顺位'
                },
                {
                    value: '1',
                    label: '第二顺位'
                },
            ],
            editstate: false,
            // 结果区
            result: {
                completed: 0,//已完成的数量
                total: 0,//总数量
                Disposal: '',//处置方式
                market: 0,//市场价值
                Realization: 0,//变现价值
                taxation: 0,//变现税费
                estimate: 0,//系统估算变现净值
                clean: 0,//抵押资产净现值
                other: 0//其他资产市场价值
            },
            //户基本信息
            basicdata: {
            },
            rulesFu: {
                //原债权人
                Name: [
                    { required: true, message: '请输入原债权人', trigger: 'blur' },
                ],
                //贷款主体
                LoansMain: [
                    { required: true, message: '请输入贷款主体', trigger: 'blur' },
                ],
                //借款本金
                Cost: [
                    { required: true, message: '请输入借款本金', trigger: 'blur' },
                ],
                //现贷款本金余额
                Balance: [
                    { required: true, message: '请输入现贷款本金余额', trigger: 'blur' },
                ],
                //贷款利息
                Interest: [
                    { required: true, message: '请输入贷款利息', trigger: 'blur' },
                ],
                //其它
                DefaultInterest: [
                    { required: true, message: '请输入其它', trigger: 'blur' },
                ],
                //债权总额
                TotalAmount: [
                    { required: true, message: '请输入债权总额', trigger: 'blur' },
                ],
                //担保分类
                GuaranteeType: [
                    { required: true, message: '请选择担保分类', trigger: 'blur' },
                ],
                HypothecateArr: [
                    { required: true, message: '请填写抵押人', trigger: 'blur' },
                ],
                //质押人
                PledgerArr: [
                    { required: true, message: '请填写质押人', trigger: 'blur' },
                ],
                //保证人
                GuarantorArr: [
                    { required: true, message: '请填写保证人', trigger: 'blur' },
                ],
                //司法进度
                Status: [
                    { required: true, message: '请选择司法进度', trigger: 'blur' },
                ],
                //购入价
                Purchase: [
                    { required: true, message: '请输入购入价', trigger: 'blur' },
                ],
                //处置价
                management: [
                    { required: true, message: '请输入处置价', trigger: 'blur' },
                ],
            },
            //担保分类列表
            guaranteelist: [
                {
                    value: '1',
                    label: '抵押'
                }, {
                    value: '2',
                    label: '质押'
                }, {
                    value: '3',
                    label: '保证'
                }, {
                    value: '0',
                    label: '未知'
                }
            ],
            //抵、质押人列表
            Pledgelist: [],
            //保证人列表
            guarantorlist: [],
            //司法进度列表
            judiciallist: [
                {
                    value: '1',
                    label: '司法立案，未开庭'
                }, {
                    value: '2',
                    label: '司法立案，已开庭（已判决）'
                }, {
                    value: '3',
                    label: '司法立案，已开庭（未判决）'
                }, {
                    value: '4',
                    label: '已判决，未上诉'
                }, {
                    value: '5',
                    label: '已判决，上诉中'
                }, {
                    value: '6',
                    label: '执行中'
                }, {
                    value: '7',
                    label: '未诉（已办理赋强文书）'
                }, {
                    value: '8',
                    label: '终本'
                }, {
                    value: '0',
                    label: '其它'
                }
            ],

            //出让单位列表
            Transferorlist: [],
            //经办部门列表
            departmentlist: [],
            //主经办人列表
            operator_alist: [],
            //协办人员列表
            operator_blist: [],

            szsel: 0,
            //中介机构列表
            agencylist: [],
            map: null,
            markerTool: null,
            geocode: null,
            poikey: '',
            asslng: '',
            asslat: '',
            assetname: '',
            winprice: false,
            baseapi: '',
            token: {
                Authorization: "Bearer "
            },
            fileimgForm: {
                max_ver: "",
                min_ver: "",
                enable: "",
                typeid: 2,
                filetype: 1,
                eid: 0
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === "image/jpeg";
                const isLt1M = file.size / 1024 / 1024 < 5;

                // if (!isJPG) {
                //   this.$message.error('上传头像图片只能是 JPG 格式!')
                // }
                if (!isLt1M) {
                    this.$message.error("上传头像图片大小不能超过 5MB!");
                }
                return isLt1M;
            },
            //图片列表
            filedatas: [],
            //当前资产信息
            rowAssetInfo: null,
            editassetinfo: null,
            tMap: null, // 腾讯地图实例
            TXMap: null, // 腾讯地图原型 
            areaprop: {
                lazy: true,
                lazyLoad: (node, resolve) => {
                    this.getCity(node).then((res) => {
                        var list = res.data.response;
                        var nodes = [];
                        list.forEach(item => {
                            if (item.Name != "市辖区") {
                                const obj = {
                                    value: item.Code,
                                    label: item.Name,
                                    leaf: node.level >= 1,
                                }
                                nodes.push(obj)
                            }
                        });
                        // resolve 节点返回
                        resolve(nodes);
                    });
                }
            },
            areavalue: null,
            areaname: '',
            areacode:'',
            cityname: '',
            markerLayer: null,
            mapinfoWindowList: null,
            costtip: '0',
            costtip2: '',
            ids: [],
            baseldshow:false,
            isDiscountLandPrice:true,
        }
    },
    mounted() {
        //#ifdef H5
        //this.initMap();
        //#endif
        this.baseapi = BaseApiUrl + '/images/Upload/Pic';
        let tokenStr = window.localStorage.Token;
        this.token = {
            Authorization: "Bearer " + tokenStr
        };
    },
    created() {
        var id = this.$route.params.id
        this.getAeestFamilydetail(id);
        this.getAssetTypeList();
        this.getCustomersList(id);
        this.getAssetsInfoList();
    },
    computed: {
        ...mapState({ // 从 state 中的到的计算属性
            catch_components: state => state.catch_components,  // keepalive缓存
            tabList: state => state.tabList,  // tags菜单列表
            active: state => state.active, // 已选中菜单
        })
    },
    methods: {
        baseldinfochange(item){ 
            this.baseldshow=!item;
        },
        derive() {
            if (this.ids.length <= 0) {
                this.$message({
                    message: '至少勾选一列数据',
                    type: 'error'
                });
            } else {
                var time = new Date();
                var nids = this.ids.join(",");
                var text = "资产导出信息" + time.toLocaleString() + ".xlsx";
                instance.get('/AssetsInfo/DownExecelInfo', { params: { ids: nids }, responseType: 'blob' }).then(res => {
                    const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                    const objectUrl = URL.createObjectURL(blob) // 创建URL
                    const link = document.createElement('a');
                    link.href = objectUrl;
                    // 这里是获取后台传过来的文件名
                    link.setAttribute('download', text)
                    link.click() // 下载文件

                });
            }
        },
        handleSelectionChange2(rows) { 
         
            this.ids = [];
            rows.forEach(res => {
                this.ids.push(res.Id);
            });
            console.log(rows)
        },
        async getCity(code) {
            var ncode = '510000000';
            if (code.level == 0) {
                ncode = '510000000';
            } else {
                ncode = code.value;
            }
            return await instance.get('/Map/GetRegionArea?cord=' + ncode);
        },
        AdScale(index) {
            var v = Number(this.basicdata.Balance) + Number(this.basicdata.Interest) + Number(this.basicdata.DefaultInterest) + Number(this.basicdata.PledgeAssetPrice);
            if (v > 0) {
                this.basicdata.TotalAmount = v
            }
            switch (index) {
                case 1:
                    if (this.basicdata.Cost > 0) {
                        this.costtip = this.basicdata.Cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        this.costtip2 = changeToChinese(this.basicdata.Cost)
                    }
                    break;
                case 2:
                    if (this.basicdata.Balance > 0) {
                        this.costtip = this.basicdata.Balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        this.costtip2 = changeToChinese(this.basicdata.Balance)
                    }
                    break;
                case 3:
                    if (this.basicdata.Interest > 0) {
                        this.costtip = this.basicdata.Interest.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        this.costtip2 = changeToChinese(this.basicdata.Interest)
                    }
                    break;
                case 4:
                    if (this.basicdata.DefaultInterest > 0) {
                        this.costtip = this.basicdata.DefaultInterest.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        this.costtip2 = changeToChinese(this.basicdata.DefaultInterest)
                    }
                    break;
                case 5:
                    if (this.basicdata.PledgeAssetPrice > 0) {
                        this.costtip = this.basicdata.PledgeAssetPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        this.costtip2 = changeToChinese(this.basicdata.PledgeAssetPrice)
                    }
                    break;
                case 6:
                    if (this.basicdata.BidPrice > 0) {
                        this.costtip = this.basicdata.BidPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        this.costtip2 = changeToChinese(this.basicdata.BidPrice)
                    }
                    break;
                case 7:
                    if (this.basicdata.BuyAssessPrice > 0) {
                        this.costtip = this.basicdata.BuyAssessPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        this.costtip2 = changeToChinese(this.basicdata.BuyAssessPrice)
                    }
                    break;
                case 8:
                    if (this.basicdata.CompensationPrice > 0) {
                        this.costtip = this.basicdata.CompensationPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        this.costtip2 = changeToChinese(this.basicdata.CompensationPrice)
                    }
                    break;
                case 9:
                    if (this.ziform.ReducedPrice > 0) {
                        this.costtip = this.ziform.ReducedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        this.costtip2 = changeToChinese(this.ziform.ReducedPrice)
                    }
                    break;
                case 10:
                    if (this.ziform.BuyAssessPrice > 0) {
                        this.costtip = this.ziform.BuyAssessPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        this.costtip2 = changeToChinese(this.ziform.BuyAssessPrice)
                    }
                    break;
                case 11:
                    if (this.ziform.NewReducedPrice > 0) {
                        this.costtip = this.ziform.NewReducedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        this.costtip2 = changeToChinese(this.ziform.NewReducedPrice)
                    }
                    break;
            }
        },
        ShowPriceTxet(index) {
            switch (index) {
                case 1:
                    if (this.basicdata.Cost > 0) {
                        this.costtip = this.basicdata.Cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        this.costtip2 = changeToChinese(this.basicdata.Cost)
                    } else {
                        this.costtip = '0';
                        this.costtip2 = '';
                    }
                    break;
                case 2:
                    if (this.basicdata.Balance > 0) {
                        this.costtip = this.basicdata.Balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        this.costtip2 = changeToChinese(this.basicdata.Balance)
                    } else {
                        this.costtip = '0';
                        this.costtip2 = '';
                    }
                    break;
                case 3:
                    if (this.basicdata.Interest > 0) {
                        this.costtip = this.basicdata.Interest.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        this.costtip2 = changeToChinese(this.basicdata.Interest)
                    } else {
                        this.costtip = '0';
                        this.costtip2 = '';
                    }
                    break;
                case 4:
                    if (this.basicdata.DefaultInterest > 0) {
                        this.costtip = this.basicdata.DefaultInterest.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        this.costtip2 = changeToChinese(this.basicdata.DefaultInterest)
                    } else {
                        this.costtip = '0';
                        this.costtip2 = '';
                    }
                    break;
                case 5:
                    if (this.basicdata.PledgeAssetPrice > 0) {
                        this.costtip = this.basicdata.PledgeAssetPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        this.costtip2 = changeToChinese(this.basicdata.PledgeAssetPrice)
                    } else {
                        this.costtip = '0';
                        this.costtip2 = '';
                    }
                    break;
                case 6:
                    if (this.basicdata.BidPrice > 0) {
                        this.costtip = this.basicdata.BidPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        this.costtip2 = changeToChinese(this.basicdata.BidPrice)
                    } else {
                        this.costtip = '0';
                        this.costtip2 = '';
                    }
                    break;
                case 7:
                    if (this.basicdata.BuyAssessPrice > 0) {
                        this.costtip = this.basicdata.BuyAssessPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        this.costtip2 = changeToChinese(this.basicdata.BuyAssessPrice)
                    } else {
                        this.costtip = '0';
                        this.costtip2 = '';
                    }
                    break;
                case 8:
                    if (this.basicdata.CompensationPrice > 0) {
                        this.costtip = this.basicdata.CompensationPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        this.costtip2 = changeToChinese(this.basicdata.CompensationPrice)
                    } else {
                        this.costtip = '0';
                        this.costtip2 = '';
                    }
                    break;
                case 9:
                    if (this.ziform.ReducedPrice > 0) {
                        this.costtip = this.ziform.ReducedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        this.costtip2 = changeToChinese(this.ziform.ReducedPrice)
                    } else {
                        this.costtip = '0';
                        this.costtip2 = '';
                    }
                    break;
                case 10:
                    if (this.ziform.BuyAssessPrice > 0) {
                        this.costtip = this.ziform.BuyAssessPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        this.costtip2 = changeToChinese(this.ziform.BuyAssessPrice)
                    } else {
                        this.costtip = '0';
                        this.costtip2 = '';
                    }
                    break;
                case 11:
                    if (this.ziform.NewReducedPrice > 0) {
                        this.costtip = this.ziform.NewReducedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        this.costtip2 = changeToChinese(this.ziform.NewReducedPrice)
                    } else {
                        this.costtip = '0';
                        this.costtip2 = '';
                    }
                    break;
            }
        },
        UpScale(value) {
            if (value > 0) {
                this.basicdata.basicdata = 0;
                this.basicdata.Interest = 0;
                this.basicdata.DefaultInterest = 0;
                this.basicdata.PledgeAssetPrice = 0;
            }
        },
        //地址改变
        handleChen(e) { 
            var thsAreaCode = this.$refs["areacascader"].getCheckedNodes()[0].pathLabels;
            var areacodes =  this.$refs["areacascader"].getCheckedNodes()[0].path;
            this.areaname = '';
            this.cityname = '';
            this.areacode ='';
            thsAreaCode.forEach(item => {
                this.areaname += item
                if (this.cityname == '') {
                    this.cityname = item;
                } 
            });
            areacodes.forEach(item => { 
                if(this.areacode==''){
                    this.areacode = item;
                }else{
                    this.areacode += '|'+item;
                }
            }); 
        },
        setsydate(val, p) {
            var y = val.getFullYear() - 30;
            var m = val.getMonth() + 1;
            var d = val.getDate();

            if (p == "fun8.B20") {
                this.fun8.B20 = y + "-" + m + "-" + d
            } else if (p == "fun10.B4") {
                this.fun10.B4 = y + "-" + m + "-" + d
            } else if (p == "fun6.D9") {
                this.fun6.D9 = y + "-" + m + "-" + d
            }
        },
        changeLiabilities(val) {
            if (val == "未知") {
                this.liashow2 = false;
            } else {
                this.liashow2 = true;
            }
        },
        changemortgage(val) {
            this.rulesnow.owner[0].required = true;
            if (val == "3") {
                this.liashow = true;
                this.liashow2 = true;
                this.qtshow = true;
            } else if (val == "4") {
                this.rulesnow.owner[0].required = false;
                this.qtshow = false;
                this.liashow = false;
                this.liashow2 = false;
            } else {
                this.qtshow = true;
                this.liashow = false;
                this.liashow2 = false;
            }
        },
        //关闭资产详细页
        AssethandleClose(done) {
            var _this = this;
            this.$confirm('确认关闭？')
                .then(_ => {
                    //_this.getAssetsInfoList()
                    _this.$router.go(0)
                    done();
                })
                .catch(_ => { });
        },
        returntop() {
            var url = `/modules/promanage1/package/${this.basicdata.AssetProjectId}`;
            this.$router.replace(url);
        },
        gotodocurl() {
            window.open(this.urls, "_blank")
        },
        changeCBType(val) {

            if (val == "基准地价") {
                if (this.rowAssetInfo.Lat == null || this.rowAssetInfo.Long == null) {
                    this.$message({
                        type: 'error',
                        message: '请选择位置'
                    });
                    this.fun4.B28 = ""
                    return;
                }

                var t_point = gcj_wgs_encrypts2(parseFloat(this.rowAssetInfo.Lat), parseFloat(this.rowAssetInfo.Long))

                let para = {
                    type: 3,
                    area: this.areacode,
                    mappoint: t_point.lon +'|'+ t_point.lat, 
                };
                getBaseLandValue(para).then((res) => {
                    if (res.data.success) {
                        if (res.data.response != null) {
                            this.islandlevel = true;
                            this.fun4.D28 = res.data.response.BaseLandPriceSquare
                            this.fun4.D999 = res.data.response.Name;
                        } else {
                            this.$message({
                                type: 'error',
                                message: '未查询到基准地价价格'
                            });
                        }

                    }
                });
            } else {
                this.$message({
                    type: 'info',
                    message: '请点击成本参考价查看数据'
                });
                this.islandlevel = false;
            }

        },
        caseSelectionChange(val) {
            this.baseCaseSelection = val;
        },
        changeJdType(val) {
            if (val == "完整") {
                this.fun3.E21 = 0;
            }

        },
        caseSelectionChange2(val) {
            this.baseCaseSelection2 = val;
        },
        changeAssettype(e) {
            this.cfgyshow = false;
            this.asstipshow = false;
            if (e == 1002 || e == 1003) {
                this.atype1name = "建筑物面积"
                this.atype1show = true;
                this.docshow = false;
                if (e == 1002) {
                    this.cfgyshow = true;
                }
            } else if (e == 1006 || e == 1007 || e == 1008 || e == 1009 || e == 1012 || e == 1013 || e == 1017) {
                if (e == 1006) {
                    this.urls = "https://api.scdamc-gz.com/wwwroot/doc/股权资料清单.docx";
                    this.docname = "股权资料清单.docx"
                }
                if (e == 1007) {
                    this.urls = "https://api.scdamc-gz.com/wwwroot/doc/采矿权相关资料清单.docx";
                    this.docname = "采矿权相关资料清单.docx"
                }
                if (e == 1008) {
                    this.urls = "https://api.scdamc-gz.com/wwwroot/doc/原酒相关资料清单.docx";
                    this.docname = "原酒相关资料清单.docx"
                }
                if (e == 1009) {
                    this.urls = "https://api.scdamc-gz.com/wwwroot/doc/林权相关资料清单.docx";
                    this.docname = "林权相关资料清单.docx"
                }
                if (e == 1013) {
                    this.urls = "https://api.scdamc-gz.com/wwwroot/doc/专利相关资料清单.docx";
                    this.docname = "专利相关资料清单.docx"
                }
                if (e == 1017) {
                    this.urls = "https://api.scdamc-gz.com/wwwroot/doc/水电站实物资产资料清单.docx";
                    this.docname = "水电站实物资产资料清单.docx"
                }
                if (e == 1012) {
                    this.urls = "https://api.scdamc-gz.com/wwwroot/doc/股权资料清单.docx";
                    this.docname = "股权资料清单.docx"
                    this.docshow = true;
                    this.atype1show = false;
                    this.$message({
                        type: 'success',
                        message: '结合企业所在行业地位、经营情况，若市场相对平稳可参考近一年内最低成交价确定其价格，若市场波动较大，参考近三年成最低成交价确定其价格。'
                    });
                    this.asstipshow = true;
                    this.assetTip = '结合企业所在行业地位、经营情况，若市场相对平稳可参考近一年内最低成交价确定其价格，若市场波动较大，参考近三年成最低成交价确定其价格。';
                } else {
                    this.docshow = true;
                    this.atype1show = false;
                    this.$message({
                        type: 'success',
                        message: '特殊类型资产请线下咨询评估公司'
                    });
                    this.asstipshow = true;
                    this.assetTip = '特殊类型资产请线下咨询评估公司';
                }


            } else {
                this.atype1show = false;
                this.docshow = false;
                this.atype1name = "评估面积"
            }
        },
        getAssetLogs(id) {
            let para = {
                aid: id,
            };
            this.activities = [];
            getAssetLog(para).then((res) => {
                var reslist = res.data.response;
                var i = 0;
                reslist.forEach(item => {
                    i++;
                    var ts = item.DisposeTypeId == 1 ? '债权转让' : item.DisposeTypeId == 2 ? "司法处置" : "以物抵债";
                    if (i == 1) {
                        var m = {
                            content: ts + '（测算人员：' + (item.CreateName == null ? "未知" : item.CreateName) + '、评估市场价：' + item.ComputePrice + '万元、变现折扣：' + item.Realization + '%、变现价值：' + item.RealizableValue + '万元、变现税费：' + item.RealizableTax + '万元、清算价值：' + item.LiquidateValue + '万元）',
                            timestamp: item.CreateTime,
                            size: 'large',
                            type: 'primary',
                            //icon: 'el-icon-more'
                        };
                        this.activities.push(m)
                    } else {
                        var m = {
                            content: ts + '（测算人员：' + (item.CreateName == null ? "未知" : item.CreateName) + '、评估市场价：' + item.ComputePrice + '万元、变现折扣：' + item.Realization + '%、变现价值：' + item.RealizableValue + '万元、变现税费：' + item.RealizableTax + '万元、清算价值：' + item.LiquidateValue + '万元）',
                            timestamp: item.CreateTime,
                        };
                        this.activities.push(m)
                    }
                })

            });
        },
        //封装验证函数
        checkForm(formName) {
            let _self = this;
            _self.resultArr = []
            let result = new Promise(function (resolve, reject) {
                _self.$refs[formName].validate((valid) => {
                    if (valid) {
                        resolve();
                    } else { reject() }
                })
            })
            _self.resultArr.push(result) //push 得到promise的结果
        },
        initMap() {
            let _this = this;
            setTimeout(function () {
                if (_this.map == null) {
                    // 3.初始化地图对象
                    let T = window.T
                    _this.map = new T.Map("mapView");
                    // 4.设置显示地图的中心点和级别
                    _this.map.centerAndZoom(new T.LngLat(104.072278, 30.663432), 12);
                    //工具
                    _this.markerTool = new T.MarkTool(_this.map, { follow: true });

                    //搜索
                    _this.geocode = new T.Geocoder();
                }
            }, 1000);
        },
        initTxMap2() {
            if (this.tMap == null) {
                TMap.init().then((TMap) => {
                    this.TXMap = TMap;
                    this.tMap = new TMap.Map("mapView", {
                        center: new TMap.LatLng(30.663432, 104.072278), //设置地图中心点坐标
                        zoom: 15, //设置地图缩放级别
                        viewMode: "2D",
                    });
                    this.loadpoint()
                });
            } else {
                this.loadpoint()
            }
        },
        setassetpoint(location, address) {
            this.asslat = location.lat;
            this.asslng = location.lng;
            this.rowAssetInfo.Long = location.lng;
            this.rowAssetInfo.Lat = location.lat;
            this.ziform.dizhi = address;
        },
        loadpoint() {
            if (this.rowAssetInfo.Long != null && this.rowAssetInfo.Long != "") {
                if (this.markerLayer != null) {
                    this.markerLayer.setGeometries([]);
                } else {
                    this.markerLayer = new this.TXMap.MultiMarker({
                        map: this.tMap, geometries: [],
                        styles: {
                            "highlight": new this.TXMap.MarkerStyle({
                                "src": "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/marker-pink.png"
                            })
                        },
                    });
                }
                var _this = this;
                if (_this.mapinfoWindowList != null) {
                    _this.mapinfoWindowList.forEach((infoWindow) => {
                        infoWindow.close();
                    });
                } else {
                    _this.mapinfoWindowList = [];
                }
                var latlng = new _this.TXMap.LatLng(this.rowAssetInfo.Lat, this.rowAssetInfo.Long);
                _this.markerLayer.add({
                    id: 101, // 点标注数据数组
                    position: latlng,
                    styleId: "highlight"
                }); // 绘制地点标注

                _this.getmapAddress(latlng);
            }
        },
        addPoint() {
            if(this.rowAssetInfo.TypeId == 1002){
                if (this.areaname == null || this.areaname == '') {
                    this.$message({
                        message: "请选择区域",
                        type: "error",
                    });
                    return
                } 
            }
            this.$message({
                type: 'warning',
                message: '请在地图上点击资产所在位置添加定位!'
            });

            if (this.markerLayer != null) {
                this.markerLayer.setGeometries([]);
            } else {
                this.markerLayer = new this.TXMap.MultiMarker({
                    map: this.tMap, geometries: [],
                    styles: {
                        "highlight": new this.TXMap.MarkerStyle({
                            "src": "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/marker-pink.png"
                        })
                    },
                });
            }
            var _this = this;
            if (_this.mapinfoWindowList != null) {
                _this.mapinfoWindowList.forEach((infoWindow) => {
                    infoWindow.close();
                });
            } else {
                _this.mapinfoWindowList = [];
            }
            var eventClick = function (e) {
                _this.markerLayer.add({
                    id: 101, // 点标注数据数组
                    position: e.latLng,
                    styleId: "highlight"
                }); // 绘制地点标注

                _this.tMap.off("click", eventClick);
                _this.getmapAddress(e.latLng);
            }
            //监听marker点击事件
            this.tMap.on("click", eventClick)
        },
        getmapAddress(location) {
            var _this = this;

            var geocoder = new this.TXMap.service.Geocoder()
            geocoder.getAddress({
                location: location,
            }).then((result) => {
                var addinfo = result.result.address;
                var infoWindow = new _this.TXMap.InfoWindow({
                    map: _this.tMap,
                    position: location,
                    content: `<p>地址：${addinfo}</p>`,
                    offset: { x: 0, y: -50 },
                }); // 新增信息窗体显示地标的名称与地址、电话等信息
                _this.mapinfoWindowList.push(infoWindow);
                _this.setassetpoint(location, addinfo)
                _this.tMap.setCenter(location)
            });
        },
        subPoint() {
            if (this.asslat == '') {
                this.$message({
                    type: 'error',
                    message: '请添加定位!'
                });
                return
            }
            this.maptc = false;
        },
        handleAvatarSuccess(res, file) {
            this.$message({
                type: 'success',
                message: res.msg
            });
            if (res.success) {
                file.name = res.response.Url;
                file.uid = res.response.Id;
            }
        },
        getValueList(id, tid) {
            let para = {
                assetid: id,
                assettypeid: tid,
            };
            getAssetTypeValueByAsset(para).then((res) => {
                if (res.data.success) {
                    this.dynamicValidateForm.fromValueList = res.data.response;
                    this.dynamicValidateForm.fromValueList.forEach(item => {
                        if (item.Type == 6) { //多选
                            if (item.RecValue != null) {
                                item.RecValue = item.RecValue.split("|");
                            } else {
                                item.RecValue = [];
                            }
                        } else if (item.Type == 7) {
                            if (item.RecValue != null) {
                                item.RecValue = new Date(item.RecValue)
                            } else {
                                item.RecValue = '';
                            }
                        }

                    });
                }

            });
        },
        getCustomersList(id) {
            let para = {
                fid: id,
            };
            this.ownerlist = [];
            getCustomersByFamily(para).then((res) => {
                var reslist = res.data.response;
                reslist.forEach(item => {
                    var tname = '';
                    if (item.TypeId == 1) {
                        tname = "（贷款主体）"
                    } else if (item.TypeId == 2) {
                        tname = "（抵押人）"
                    } else if (item.TypeId == 3) {
                        tname = "（保证人）"
                    } else {
                        tname = "（质押人）"
                    }
                    this.ownerlist.push({
                        value: item.Id,
                        label: item.Name + tname
                    });
                })

            });
        },
        //获取图片
        getAssetImgList(id) {
            let para = {
                filetype: 1,
                typeid: 2,
                eid: id
            };
            this.filedatas = [];
            getFilesTableList(para).then((res) => {
                var reslist = res.data.response;
                reslist.forEach(item => {
                    this.filedatas.push({
                        name: item.Name,
                        url: BaseApiUrl + '/' + item.PathUrl,
                        uid: item.Id,
                    });
                })
            });
        },
        //获取资产类型
        getAssetTypeList() {
            let para = {
                page: 1,
                intPageSize: 1000
            };
            getAssetTypeListPage(para).then((res) => {
                var reslist = res.data.response.data;
                this.typelist = [];
                reslist.forEach(item => {
                    this.typelist.push({
                        value: item.Id,
                        label: item.Name
                    });
                })

            });
        },
        //获取原债权人详情
        getAeestFamilydetail(id) {
            instance.get('/AeestFamily/GetInfo?id=' + id).then(res => {
                if (res.data.success) {
                    this.basicdata = res.data.response
                    var tarr = this.basicdata.GuaranteeType.split(",");
                    var pname = [];
                    this.guaranteelist.forEach(i => {
                        tarr.forEach(i2 => {
                            if (i.value == i2) {
                                pname.push(i.label)
                            }
                        })
                    });
                    this.basicdata.GuaranteeType = pname.join(",");
                    this.judiciallist.forEach(i => {
                        if (this.basicdata.Status == i.value) {
                            this.basicdata.Status = i.label
                        }
                    });
                    this.pledgeAssetlist.forEach(i => {
                        if (this.basicdata.PledgeAssetType == i.value) {
                            this.basicdata.PledgeAssetType = i.label
                        }
                    });
                }
            });
        },
        //获取资产列表
        getAssetsInfoList() {
            var tyid = this.searchform.source;
            if (tyid == null) {
                var urll = '/AssetsInfo/Get?page=' + this.paging.currentPage + '&intPageSize=' + this.paging.size + '&fid=' + this.$route.params.id;
            } else {
                var urll = '/AssetsInfo/Get?page=' + this.paging.currentPage + '&intPageSize=' + this.paging.size + '&fid=' + this.$route.params.id + '&modeid=' + tyid;
            }

            instance.get(urll).then(res => {
                if (res.data.success) {
                    this.tableData = res.data.response.data
                    this.tableData.forEach(item => {
                        this.mortgagelist.forEach(i => {
                            if (item.ComputeMode == i.value) {
                                item.ComputeModeName = i.label
                            }
                        });
                        this.typelist.forEach(i => {
                            if (item.TypeId == i.value) {
                                item.TypeName = i.label;
                            }
                        });
                        this.managementlist.forEach(i => {
                            if (item.DisposeTypeId == i.key) {
                                item.DisposeTypeName = i.value
                            }
                        });
                    });

                }
                this.paging.total = res.data.response.dataCount
            });
        },
        checkStatus(row) {
            const selectArr = this.$refs.multipleTable.store.states.selection
            const state = selectArr.some(item => item.id == row.id)
            return selectArr.length < 3 || state
        },
        handleSelectionChange(val) {
            this.ziform.multipleSelection = val;
        },
        //录入案例
        anliekaiguan() {
            this.$nextTick(() => {
                // 禁用右键
                document.oncontextmenu = new Function("event.returnValue=false");
                // 禁用选择
                document.onselectstart = new Function("event.returnValue=false");
            });
            this.getHouseData()
            this.anlietc = true
        },
        anliekaiguan2(index) {
            this.szsel = index;
            this.getLandData()
            this.anlietc2 = true
        },
        //地图输入框搜索
        searchzhi() {
            if (this.areaname == null || this.areaname == '') {
                this.$message({
                    message: "请选择区域",
                    type: "error",
                });
                return
            }
            //根据输入框获取对应经纬度坐标
            var keyvalue = this.areaname + this.poikey;
            var _this = this;
            if (keyvalue != "") {
                var search = new this.TXMap.service.Search({ pageSize: 10 }); // 新建一个地点搜索类
                var markers = new this.TXMap.MultiMarker({
                    map: this.tMap, geometries: [],
                    styles: {
                        "highlight": new this.TXMap.MarkerStyle({
                            "src": "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/marker-pink.png"
                        })
                    },
                });
                var infoWindowList = Array(10);

                infoWindowList.forEach((infoWindow) => {
                    infoWindow.close();
                });
                infoWindowList.length = 0;
                markers.setGeometries([]);
                // 在地图显示范围内以给定的关键字搜索地点
                search.searchRegion({
                    keyword: keyvalue,
                    cityName: _this.cityname,
                }).then((result) => {
                    var cenpoi = null;
                    result.data.forEach((item, index) => {
                        var geometries = markers.getGeometries();
                        var infoWindow = new this.TXMap.InfoWindow({
                            map: _this.tMap,
                            position: item.location,
                            content: `<h3>${item.title}</h3><p>地址：${item.address != null ? item.address : ""}</p><p>电话：${item.tel != null ? item.tel : ""}</p>`,
                            offset: { x: 0, y: -50 },
                        }); // 新增信息窗体显示地标的名称与地址、电话等信息


                        if (index == 0) {
                            cenpoi = item.location;
                            geometries.push({
                                id: String(index), // 点标注数据数组
                                position: item.location,
                                styleId: "highlight",
                                value: item.address != null ? item.address : ""
                            });
                            _this.setassetpoint(item.location, item.address != null ? item.address : "")
                        } else {
                            infoWindow.close();
                            geometries.push({
                                id: String(index), // 点标注数据数组
                                position: item.location,
                                value: item.address != null ? item.address : ""
                            });
                        }
                        infoWindowList[index] = infoWindow;
                        markers.updateGeometries(geometries); // 绘制地点标注

                    });
                    markers.on('click', (e) => {
                        var mars = markers.getGeometries();
                        mars.forEach(item => {
                            if (item.id == e.geometry.id) {
                                item.styleId = "highlight";
                                markers.updateGeometries(item);

                                _this.setassetpoint(item.position, item.value)
                            } else {
                                item.styleId = "default";
                                markers.updateGeometries(item);
                            }
                        });
                        infoWindowList.forEach((infoWindow) => {
                            infoWindow.close();
                        });
                        infoWindowList[Number(e.geometry.id)].open();
                    }); // 点击标注显示信息窗体
                    //设置中心点
                    if (cenpoi != null) {
                        _this.tMap.setCenter(cenpoi)
                        _this.tMap.setZoom(17)
                    }

                });
                if (_this.mapinfoWindowList != null) {
                    _this.mapinfoWindowList.forEach((infoWindow) => {
                        infoWindow.close();
                    });
                } else {
                    _this.mapinfoWindowList = infoWindowList;
                }
                if (_this.markerLayer != null) {
                    _this.markerLayer.setGeometries([]);
                }
                _this.markerLayer = markers;
            }
        },
        tcwinprice() {
            this.winprice = true
        },
        cklManagement(value) {
            if (value == 3) {
                this.$message({
                    duration: 0,
                    showClose: true,
                    message: '抵债后以处置方式进行现金回收，需考虑再次处置时所产生的税费。',
                    type: "warning",
                });
            }

        },
        cklTechnique(value, id) {

            if (value == true && id == 1) {
                var info = '若系统未查询到相关案例，建议使用其他方法。';
                if (this.rowAssetInfo.TypeId == 2) {
                    info = '若系统未查询到相关案例，建议使用其他方法。若涉及多层商业用房，建议线下咨询专业估价人员。'
                }
                this.$message({
                    duration: 0,
                    showClose: true,
                    message: info,
                    type: "warning",
                });
            }

        },
        //测试方法改变
        revisete(value) {
            this.anlishow = false
            this.szjbdjshow = false
            this.gyjzdjshow = false
            this.fun1show = false
            this.fun2show = false
            this.fun3show = false
            this.fun4show = false
            this.fun5show = false
            this.fun6show = false
            this.fun7show = false
            this.fun8show = false
            this.fun9show = false
            this.fun10show = false
            for (let i = 0; i <= value.length; i++) {
                if (value[i] == 6) {
                    this.szjbdjshow = true;
                    continue
                }
                if (value[i] == 7) {
                    this.gyjzdjshow = true;
                    continue
                }
                if (value[i] == 2) {
                    this.fun2show = true;
                    continue;
                }
                if (value[i] == 1) {

                    this.fun1show = true;
                    continue;
                }
                if (value[i] == 3) {
                    this.fun3show = true;
                    continue;
                }
                if (value[i] == 4) {
                    this.fun4show = true;
                    continue;
                }
                if (value[i] == 5) {
                    this.fun5show = true;
                    continue;
                }
                if (value[i] == 6) {
                    this.fun6show = true;
                    continue;
                }

                if (value[i] == 7) {
                    this.fun7show = true;
                    continue;
                }
                if (value[i] == 8) {
                    this.fun8show = true;
                    continue;
                }
                if (value[i] == 9) {
                    this.fun9show = true;
                    continue;
                }
                if (value[i] == 10) {
                    this.fun10show = true;
                    continue;
                }
            }
        },
        beforeRemove(file, fileList) {
            if (file.status === "success") {
                return this.$confirm("确认删除该图片吗?", "确认删除");
            }
        },
        handleDownload(file) {
            var a = document.createElement("a"); //创建一个<a></a>标签
            a.href = BaseApiUrl + "/images/Down1/Pic?fid=" + file.uid; // 给a标签的href属性值加上地址，注意，这里是绝对路径，不用加 点.         
            a.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
        },
        //移除图片
        handleRemove(file, fileList) {
            this.listLoading = true;
            let para = { id: file.uid };
            removeFilesTable(para).then((res) => {
                this.listLoading = false;
                if (res.data.success) {
                    this.$message({
                        message: "删除成功",
                        type: "success",
                    });
                    this.getAssetImgList(this.fileimgForm.eid);
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: "error",
                    });
                }
            });
        },
        handlePictureCardPreview(file) {
            this.ziform.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        //地图弹窗
        ditutc() {
            this.maptc = true
            //this.initMap();
            this.initTxMap2();
        },
        //查看
        handleClick(row, index) {
            this.anlishow = false
            this.szjbdjshow = false
            this.gyjzdjshow = false
            this.fun1show = false
            this.fun2show = false
            this.fun3show = false
            this.fun4show = false
            this.fun5show = false
            this.fun6show = false
            this.fun7show = false
            this.fun8show = false
            this.fun9show = false
            this.fun10show = false
            this.baseldshow =false
            this.fun1.D4 = ''
            this.fun8.D5 = ''
            this.ziform2.technique = [];
            this.rowAssetInfo = row;
            this.drawer = true
            this.ziform.name = row.Name;
            this.ziform.ReducedPrice = row.ReducedPrice;
            this.ziform.BuyAssessPrice = row.BuyAssessPrice;
            this.ziform.NewReducedPrice = row.NewReducedPrice;
            this.assetname = "【 序号：" + (index + 1) + " 】 【 资产名称：" + row.Name + " 】 【 资产类型：" + row.TypeName + " 】 【 担保情况：" + row.ComputeModeName + " 】";
            this.fileimgForm.eid = row.Id;
            this.ziform.dizhi = row.LocationName;

            if (row.TypeId == 1) {
                this.fun1.C4 = '住宅'
            } else if (row.TypeId == 2) {
                this.fun1.C4 = '商业'
            } else if (row.TypeId == 3) {
                this.fun1.C4 = '办公'
            } else if (row.TypeId == 4) {
                this.fun1.C4 = '车位'
            }

            this.ziform.Floor = row.Floor;
            this.ziform.Flaw = row.Flaw;
            if (row.Flaw != null) {
                this.flawlist = row.Flaw.split(",");
            }

            this.ziform.Remark = row.Remark;
            this.ziform.NewRemark = row.NewRemark;
            this.ziform.DisposalPriceRemark = row.DisposalPriceRemark;
            this.asslat = row.Lat;
            this.asslng = row.Long;
            this.areacode = row.AreaCode;
            if(row.AreaCode!=null&&row.AreaCode.length>0){
                var areacodearr = row.AreaCode.split('|'); 
                this.areavalue = areacodearr;
            }

            if (this.rowAssetInfo.DataType == 2) {
                this.clshow = false;
                this.qtzcshow = false;
                //为存量资产包是也要调用展示图片接口
                this.getAssetImgList(row.Id)
            } else {
                if (this.rowAssetInfo.TypeId == 1006 || this.rowAssetInfo.TypeId == 1007 || this.rowAssetInfo.TypeId == 1008 || this.rowAssetInfo.TypeId == 1009 || this.rowAssetInfo.TypeId == 1012) {
                    this.clshow = false;
                    this.qtzcshow = true;
                    this.ziform.RealizableValue = row.RealizableValue;
                    this.ziform.RealizableTax = row.RealizableTax;
                    this.ziform.LiquidateValue = row.LiquidateValue;
                    this.ziform.ComputePrice = row.ComputePrice;
                } else {
                    this.clshow = true;
                    this.qtzcshow = false;
                    this.getValueList(row.Id, row.TypeId)
                    this.getAssetImgList(row.Id)
                    this.getComputeType(row.TypeId)
                    this.getAssetLogs(row.Id)

                    this.ziform2.discount = row.Realization == null ? 0 : row.Realization;

                    this.ziform2.management = row.DisposeTypeId;
                    this.ziform2.area = row.ComputeArea;
                    this.resPrice = "评估单价：" + row.UnitPrice + "元/㎡；评估总价：" + row.TotalPrice + "万元"
                    this.asslat = row.Lat;
                    this.asslng = row.Long;
                    this.setComputeValue()
                    //房产-住宅
                    if (this.rowAssetInfo.TypeId == 1) {
                        this.discountTip = "60%-90%";
                    }
                    //房产-商业
                    else if (this.rowAssetInfo.TypeId == 2) {
                        this.discountTip = "根据区位状况及实际经营情况取值40%-80%";
                    }
                    //房产-办公
                    else if (this.rowAssetInfo.TypeId == 3) {
                        this.discountTip = "根据区位状况及实际经营情况取值40%-80%";
                    }
                    //房产-车位
                    else if (this.rowAssetInfo.TypeId == 4) {
                        this.discountTip = "50%-70%";
                    }
                    //房产-酒店
                    else if (this.rowAssetInfo.TypeId == 1010) {
                        this.discountTip = "根据区位状况及实际经营情况取值40%-80%";
                    }
                    //房产-工业
                    else if (this.rowAssetInfo.TypeId == 1002) {
                        this.discountTip = "50%-70%";
                    }
                    //房产-在建工程
                    else if (this.rowAssetInfo.TypeId == 1003) {
                        this.discountTip = "50%-70%";
                    }
                    //土地-住宅土地-商业
                    else if (this.rowAssetInfo.TypeId == 1004 || this.rowAssetInfo.TypeId == 1011) {
                        this.discountTip = "60%-80%";
                    }
                    //土地-工业
                    else if (this.rowAssetInfo.TypeId == 1005) {
                        this.discountTip = "50%-70%";
                    }
                }

            }


        },
        setComputeValue() {
            var _this = this;
            if (this.rowAssetInfo.ComputeValue == null || this.rowAssetInfo.ComputeValue.length <= 0) {
                return;
            }
            var vlist = JSON.parse(this.rowAssetInfo.ComputeValue);
            vlist.forEach(item => {
                if (item.id == 999) {

                } else {
                    var bname = 'fun' + item.id;
                    var funm = _this[bname];
                    item.value.forEach(item2 => {
                        var ivalue = item2.Value;
                        if (item.id == 4 || item.id == 5) {
                            ivalue = item2.Value.replace(/"/g, "");
                        }
                        if(ivalue=="true"){
                            funm[item2.Code]  = true; 
                        }else if(ivalue=="false"){
                            funm[item2.Code] = false; 
                            if(item.id==6||item.id==7){
                                _this.baseldshow =true;
                            }
                        } else{
                            funm[item2.Code] = ivalue;
                        } 
                    })
                }
            })
        },
        getLandData() {
            var atv = 1;
            if (this.rowAssetInfo.TypeId == 1004) {
                atv = 1;
            } else if (this.rowAssetInfo.TypeId == 1011) {
                atv = 2;
            } else if (this.rowAssetInfo.TypeId == 1005) {
                atv = 3;
            }

            var t_point = gcj_wgs_encrypts2(parseFloat(this.rowAssetInfo.Lat), parseFloat(this.rowAssetInfo.Long))
            let para = {
                x: t_point.lon,
                y: t_point.lat,
                max: 5000,
                t: atv
            };
            var _ = this;
            getNearSphereLand(para).then((res) => {
                var reslist = res.data.response;
                _.tbBaseCaseData2 = [];
                reslist.forEach(item => { 
                    var dtvalue = {
                        Name: item.Name,
                        Area: item.Area,
                        Price: item.Price,
                        TradeDate: item.TradeDate != null ? item.TradeDate.substring(0, 10) : item.TradeDate,
                        PlotRatio: item.PlotRatio.toFixed(1),
                        Id: item.Id,
                        ServiceYear: item.ServiceYear,
                        PurposeType: this.setPurposeTypeName(item.TypeId),
                        DisPrice:"未提供"
                    }
                    if(this.szsel==2){
                        this.isDiscountLandPrice=true;
                        if(item.Price>0&&item.ServiceYear>0){
                            dtvalue.DisPrice=(item.Price/(item.ServiceYear/50)).toFixed(2)
                        }else{
                            dtvalue.DisPrice=0
                        }
                        
                    }else{
                        this.isDiscountLandPrice= false;
                    }
                    _.tbBaseCaseData2.push(dtvalue)
                })
            });
        },
        setPurposeTypeName(val) {
            if (val == 1) {
                return "住宅用地"
            } else if (val == 2) {
                return "商业用地"
            } else if (val == 3) {
                return "工业用地"
            } else if (val == 4) {
                return "公共管理与公共服务用地"
            } else {
                return "其它"
            }
        },
        addLandData() {
            if (this.baseCaseSelection2.length <= 3) {
                var _ = this;
                var i = 0;
                this.baseCaseSelection2.forEach(item => {
                    i++;
                    if (_.szsel == 1) {
                        if (i == 1) {
                            _.fun8.D5 = item.TradeDate;
                            _.fun8.D7 = item.Area;
                            _.fun8.D9 = item.PlotRatio;
                            _.fun8.D12 = item.Price;
                            _.fun8.J30 = 100;
                            _.fun8.Name1 = item.Name;
                        } else if (i == 2) {
                            _.fun8.E5 = item.TradeDate;
                            _.fun8.E7 = item.Area;
                            _.fun8.E9 = item.PlotRatio;
                            _.fun8.E12 = item.Price;
                            _.fun8.K30 = 100;
                            _.fun8.Name2 = item.Name;
                        } else if (i == 3) {
                            _.fun8.F5 = item.TradeDate;
                            _.fun8.F7 = item.Area;
                            _.fun8.F9 = item.PlotRatio;
                            _.fun8.F12 = item.Price;
                            _.fun8.L30 = 100;
                            _.fun8.Name3 = item.Name;
                        }
                    } else if (_.szsel == 2) { 
                        if (i == 1) {
                            _.fun9.D5 = item.TradeDate;
                            _.fun9.D7 = item.Area;
                            _.fun9.D9 = item.ServiceYear;
                            _.fun9.D12 = item.DisPrice;
                            _.fun9.J30 = 100;
                            _.fun9.Name1 = item.Name;

                        } else if (i == 2) {
                            _.fun9.E5 = item.TradeDate;
                            _.fun9.E7 = item.Area;
                            _.fun9.E9 = item.ServiceYear;
                            _.fun9.E12 = item.DisPrice;
                            _.fun9.K30 = 100;
                            _.fun9.Name2 = item.Name;
                        } else if (i == 3) {
                            _.fun9.F5 = item.TradeDate;
                            _.fun9.F7 = item.Area;
                            _.fun9.F9 = item.ServiceYear;
                            _.fun9.F12 = item.DisPrice;
                            _.fun9.L30 = 100;
                            _.fun9.Name3 = item.Name;
                        }
                    }
                })
                this.anlietc2 = false;
            } else {
                this.$message({
                    message: "最多只能选择3个案例",
                    type: "error",
                });
            }


        },
        getHouseData() {
            var ht = 0;
            var max = 1000;
            if (this.rowAssetInfo.TypeId == 1) {
                //住宅
                ht = 1;
            } else if (this.rowAssetInfo.TypeId == 2) {
                //商业
                ht = 2;
                max = 2000;
            } else if (this.rowAssetInfo.TypeId == 3) {
                //办公
                ht = 4;
                max = 2000;
            } else if (this.rowAssetInfo.TypeId == 1002) {
                //工业
                ht = 3;
                max = 3000;
            }

            var t_point = gcj_wgs_encrypts2(parseFloat(this.rowAssetInfo.Lat), parseFloat(this.rowAssetInfo.Long))
            let para = {
                x: t_point.lon,
                y: t_point.lat,
                max: max,
                t: ht
            };
            var _ = this;
            _.alloading = true;
            getNearSphereHouse(para).then((res) => {
                var reslist = res.data.response;
                console.log(reslist);
                _.tbBaseCaseData = [];
                if(reslist!=null){ 
                    reslist.forEach(item => {
                        _.tbBaseCaseData.push({
                            Name: item.Name,
                            Area: item.Area,
                            Price: item.Price,
                            Type: item.Type,
                            TradeDate: item.TradeDate != null ? item.TradeDate.substring(0, 10) : item.TradeDate,
                            UnitPrice: item.UnitPrice,
                            Location: item.Location,
                            Floor:item.Floor+'/'+item.TotalFloor
                        })
                    }) 
                }
                _.alloading = false;
            });
        },
        addHouseCaseData() {
            if (this.baseCaseSelection.length <= 3) {
                var _ = this;
                var i = 0;
                this.baseCaseSelection.forEach(item => {
                    i++;
                    if (i == 1) {
                        _.fun1.D4 = item.Type;
                        _.fun1.D5 = item.Area;
                        _.fun1.D6 = item.Price;
                        _.fun1.D8 = item.UnitPrice;
                        _.fun1.C14 = 100;
                        _.fun1.Name1 = item.Name;
                    } else if (i == 2) {
                        _.fun1.E4 = item.Type;
                        _.fun1.E5 = item.Area;
                        _.fun1.E6 = item.Price;
                        _.fun1.E8 = item.UnitPrice;
                        _.fun1.D14 = 100;
                        _.fun1.Name2 = item.Name;
                    } else if (i == 3) {
                        _.fun1.F4 = item.Type;
                        _.fun1.F5 = item.Area;
                        _.fun1.F6 = item.Price;
                        _.fun1.F8 = item.UnitPrice;
                        _.fun1.E14 = 100;
                        _.fun1.Name3 = item.Name;
                    }
                })
                this.anlietc = false;
            } else {
                this.$message({
                    message: "最多只能选择3个案例",
                    type: "error",
                });
            }

        },
        getComputeType(id) {
            let para = {
                tid: id,
            };
            this.techniquelist = [];
            var selcom = [];
            if (this.rowAssetInfo.ComputeTypeIds != null && this.rowAssetInfo.ComputeTypeIds.length > 0) {
                selcom = this.rowAssetInfo.ComputeTypeIds.split(",");
            }
            getComputeTypeByAssetType(para).then((res) => {
                var reslist = res.data.response;
                reslist.forEach(item => {
                    this.techniquelist.push({
                        key: item.Id,
                        value: item.Name
                    });

                    selcom.forEach(item2 => {
                        if (item2 == item.Id) {
                            this.ziform2.technique.push(item.Id)
                            if (item2 == 6) {
                                this.szjbdjshow = true;
                            }
                            if (item2 == 7) {
                                this.gyjzdjshow = true;
                            }
                            if (item2 == 8) {
                                this.anlishow = true;
                            }
                            if (item2 == 2) {
                                this.fun2show = true;
                            }
                            if (item2 == 1) {
                                this.fun1show = true;
                            }
                            if (item2 == 3) {
                                this.fun3show = true;
                            }
                            if (item2 == 4) {
                                this.fun4show = true;
                            }
                            if (item2 == 5) {
                                this.fun5show = true;
                            }
                            if (item2 == 6) {
                                this.fun6show = true;
                            }

                            if (item2 == 7) {
                                this.fun7show = true;
                            }
                            if (item2 == 8) {
                                this.fun8show = true;
                            }
                            if (item2 == 9) {
                                this.fun9show = true;
                            }
                            if (item2 == 10) {
                                this.fun10show = true;
                            }
                        }
                    });

                })

            });
        },
        subAssetData(type) {
            var _this = this;
            this.$confirm("确认提交吗？", "提示", {}).then(() => {
                this.ziform2.technique.forEach(item => {
                    this.formArr.push('fun' + item + 'ref');
                });
                let _self = this;
                _self.formArr.forEach(item => { //根据表单的ref校验
                    _self.checkForm(item)
                })

                Promise.all(_self.resultArr).then(function () { //都通过了

                    _this.editLoading = true;

                    var tids = '';
                    if (_this.ziform2.technique.length > 0) {
                        tids = _this.ziform2.technique.join(',');
                    }
                    var dtid = 0;
                    if (_this.ziform2.management != null) {
                        dtid = _this.ziform2.management;
                    }
                    var typevalue = null;
                    if (_this.dynamicValidateForm.fromValueList != null & _this.dynamicValidateForm.fromValueList.length > 0) {
                        typevalue = [];
                        _this.dynamicValidateForm.fromValueList.forEach(item => {
                            var fv = { Key: item.Id, Value: item.RecValue, Code: item.Code };
                            typevalue.push(fv)
                        });
                    }
                    var baselandis = 0;
                    var computevalue = [{ id: 999, value: [{ key: "I5", Value: (_this.ziform2.discount / 100), Code: "I5" }] }];
                    _this.ziform2.technique.forEach(item => {
                        //房产比较法
                        if (item == 1) {
                            var vlist = [];
                            vlist.push({ Key: "C4", value: _this.fun1.C4, Code: "C4" })
                            vlist.push({ Key: "C5", Value: _this.fun1.C5, Code: "C5" })
                            vlist.push({ Key: "C17", Value: _this.fun1.C17, Code: "C17" })
                            //案例一
                            vlist.push({ Key: "D4", Value: _this.fun1.D4, Code: "D4" })
                            vlist.push({ Key: "D5", Value: _this.fun1.D5, Code: "D5" })
                            vlist.push({ Key: "D6", Value: _this.fun1.D6, Code: "D6" })
                            vlist.push({ Key: "C14", Value: _this.fun1.C14, Code: "C14" })
                            vlist.push({ Key: "Name1", Value: _this.fun1.Name1, Code: "Name1" })
                            vlist.push({ Key: "D8", Value: _this.fun1.D8, Code: "D8" })
                            //案例二
                            vlist.push({ Key: "E4", Value: _this.fun1.E4, Code: "E4" })
                            vlist.push({ Key: "E5", Value: _this.fun1.E5, Code: "E5" })
                            vlist.push({ Key: "E6", Value: _this.fun1.E6, Code: "E6" })
                            vlist.push({ Key: "D14", Value: _this.fun1.D14, Code: "D14" })
                            vlist.push({ Key: "Name2", Value: _this.fun1.Name2, Code: "Name2" })
                            vlist.push({ Key: "E8", Value: _this.fun1.E8, Code: "E8" })
                            //案例三
                            vlist.push({ Key: "F4", Value: _this.fun1.F4, Code: "F4" })
                            vlist.push({ Key: "F5", Value: _this.fun1.F5, Code: "F5" })
                            vlist.push({ Key: "F6", Value: _this.fun1.F6, Code: "F6" })
                            vlist.push({ Key: "E14", Value: _this.fun1.E14, Code: "E14" })
                            vlist.push({ Key: "Name3", Value: _this.fun1.Name3, Code: "Name3" })
                            vlist.push({ Key: "F8", Value: _this.fun1.F8, Code: "F8" })
                            var v = { id: item, value: vlist }
                            computevalue.push(v)
                        }
                        //房地产收益法
                        if (item == 2) {
                            //{ D1: "否", C6:"估价对象实际租金",D3: "", D4: "", D6: "", E6: "0", F3: "",F4: "", F6: "" ,G4:""},
                            var vlist = [];
                            vlist.push({ Key: "D1", Value: _this.fun2.D1, Code: "D1" })
                            vlist.push({ Key: "D3", Value: _this.fun2.D3, Code: "D3" })
                            vlist.push({ Key: "D4", Value: _this.fun2.D4, Code: "D4" })
                            vlist.push({ Key: "D6", Value: _this.fun2.D6, Code: "D6" })
                            vlist.push({ Key: "E6", Value: _this.fun2.E6, Code: "E6" })
                            vlist.push({ Key: "F3", Value: _this.fun2.F3, Code: "F3" })
                            vlist.push({ Key: "F4", Value: _this.fun2.F4, Code: "F4" })
                            vlist.push({ Key: "F6", Value: _this.fun2.F6, Code: "F6" })
                            vlist.push({ Key: "G4", Value: _this.fun2.G4, Code: "G4" })
                            vlist.push({ Key: "C6", Value: _this.fun2.C6, Code: "C6" })
                            vlist.push({ Key: "D23", Value: _this.fun2.D23, Code: "D23" })
                            vlist.push({ Key: "D24", Value: _this.fun2.D24, Code: "D24" })
                            var v = { id: item, value: vlist }
                            computevalue.push(v)
                        }
                        //酒店收益法
                        if (item == 3) {
                            var vlist = [];
                            vlist.push({ Key: "B1", Value: _this.fun3.B1, Code: "B1" })
                            vlist.push({ Key: "B2", Value: _this.fun3.B2, Code: "B2" })
                            vlist.push({ Key: "B3", Value: _this.fun3.B3, Code: "B3" })
                            vlist.push({ Key: "B4", Value: _this.fun3.B4, Code: "B4" })
                            vlist.push({ Key: "E20", Value: _this.fun3.E20, Code: "E20" })
                            vlist.push({ Key: "E21", Value: _this.fun3.E21, Code: "E21" })

                            vlist.push({ Key: "B10", Value: _this.fun3.B10, Code: "B10" })
                            vlist.push({ Key: "C10", Value: _this.fun3.C10, Code: "C10" })
                            vlist.push({ Key: "D10", Value: _this.fun3.D10, Code: "D10" })

                            vlist.push({ Key: "B14", Value: _this.fun3.B14, Code: "B14" })
                            vlist.push({ Key: "C14", Value: _this.fun3.C14, Code: "C14" })
                            vlist.push({ Key: "D14", Value: _this.fun3.D14, Code: "D14" })

                            vlist.push({ Key: "B18", Value: _this.fun3.B18, Code: "B18" })
                            vlist.push({ Key: "C18", Value: _this.fun3.C18, Code: "C18" })
                            vlist.push({ Key: "D18", Value: _this.fun3.D18, Code: "D18" })

                            vlist.push({ Key: "B31", Value: _this.fun3.B31, Code: "B31" })
                            vlist.push({ Key: "B32", Value: _this.fun3.B32, Code: "B32" })

                            var v = { id: item, value: vlist }
                            computevalue.push(v)
                        }

                        //房地产成本法
                        if (item == 4) {
                            var vlist = [];
                            vlist.push({ Key: "B1", Value: _this.fun4.B1, Code: "B1" })
                            vlist.push({ Key: "C13", Value: _this.fun4.C13, Code: "C13" })
                            vlist.push({ Key: "D13", Value: _this.fun4.D13, Code: "D13" })
                            vlist.push({ Key: "E13", Value: _this.fun4.E13, Code: "E13" })
                            vlist.push({ Key: "F13", Value: "\"" + _this.fun4.F13 + "\"", Code: "F13" })
                            vlist.push({ Key: "G13", Value: _this.fun4.G13, Code: "G13" })
                            vlist.push({ Key: "D28", Value: _this.fun4.D28, Code: "D28" })
                            vlist.push({ Key: "B28", Value: _this.fun4.B28, Code: "B28" })

                            vlist.push({ Key: "D999", Value: _this.fun4.D999, Code: "D999" })

                            var v = { id: item, value: vlist }
                            computevalue.push(v)
                        }

                        //在建工程假设开发法
                        if (item == 5) {
                            var vlist = [];
                            vlist.push({ Key: "B1", Value: _this.fun5.B1, Code: "B1" })
                            vlist.push({ Key: "B2", Value: _this.fun5.B2, Code: "B2" })
                            vlist.push({ Key: "C15", Value: "\"" + _this.fun5.C15 + "\"", Code: "C15" })
                            vlist.push({ Key: "E15", Value: _this.fun5.E15, Code: "E15" })
                            var g15value = _this.fun5.F23 - _this.fun5.G23
                            vlist.push({ Key: "G15", Value: g15value, Code: "G15" })
                            vlist.push({ Key: "H15", Value: _this.fun5.H15, Code: "H15" })
                            var v = { id: item, value: vlist }
                            computevalue.push(v)
                        }

                        //商住基准地价系数修正法
                        // fun6:{D5:"",D6:"",D7:"",D8:"",D9:"",D10:"",K2:"",K3},
                        if (item == 6) {
                            baselandis = 1;
                            var vlist = [];
                            vlist.push({ Key: "", Value: _this.fun6.D5, Code: "D5" })
                            vlist.push({ Key: "", Value: _this.fun6.D6, Code: "D6" })
                            vlist.push({ Key: "", Value: _this.fun6.D7, Code: "D7" })
                            vlist.push({ Key: "", Value: _this.fun6.D8, Code: "D8" })
                            vlist.push({ Key: "", Value: _this.fun6.D9, Code: "D9" })
                            vlist.push({ Key: "", Value: _this.fun6.D10, Code: "D10" })
                            vlist.push({ Key: "", Value: _this.fun6.K2, Code: "K2" })
                            vlist.push({ Key: "", Value: _this.fun6.K3, Code: "K3" })
                            vlist.push({ Key: "D15", Value: _this.fun6.D15, Code: "D15" })
                            vlist.push({ Key: "D16", Value: _this.fun6.D16, Code: "D16" })
                            vlist.push({ Key: "D17", Value: _this.fun6.D17, Code: "D17" }) 
                            vlist.push({ Key: "D23", Value: _this.fun6.D23, Code: "D23" })
                            vlist.push({ Key: "D24", Value: _this.fun6.D24, Code: "D24" }) 
                            vlist.push({ Key: "A2", Value: _this.fun6.A2, Code: "A2" })
                            var v = { id: item, value: vlist }
                            computevalue.push(v)

                            
                        }
                        //工业基准地价系数修正法
                        if (item == 7) {
                            baselandis = 2;
                            var vlist = [];
                            vlist.push({ Key: "C5", Value: _this.fun7.C5, Code: "C5" })
                            vlist.push({ Key: "C6", Value: _this.fun7.C6, Code: "C6" })
                            vlist.push({ Key: "C7", Value: _this.fun7.C7, Code: "C7" })
                            vlist.push({ Key: "C12", Value: _this.fun7.C12, Code: "C12" })
                            vlist.push({ Key: "I2", Value: _this.fun7.I2, Code: "I2" }) 
                            vlist.push({ Key: "C11", Value: _this.fun7.C11, Code: "C11" })
                            vlist.push({ Key: "C12", Value: _this.fun7.C12, Code: "C12" }) 
                            vlist.push({ Key: "A2", Value: _this.fun7.A2, Code: "A2" })
                            var v = { id: item, value: vlist }
                            computevalue.push(v)
                        }
                        //商住土地市场法
                        if (item == 8) {
                            var vlist = [];
                            vlist.push({ Key: "C5", value: _this.fun8.C5, Code: "C5" })
                            vlist.push({ Key: "C7", Value: _this.fun8.C7, Code: "C7" })
                            vlist.push({ Key: "C9", Value: _this.fun8.C9, Code: "C9" })
                            vlist.push({ Key: "C11", Value: _this.fun8.C11, Code: "C11" })
                            vlist.push({ Key: "B15", Value: _this.fun8.B15, Code: "B15" })
                            vlist.push({ Key: "B20", Value: _this.fun8.B20, Code: "B20" })
                            //案例一
                            vlist.push({ Key: "D5", Value: _this.fun8.D5, Code: "D5" })
                            vlist.push({ Key: "D7", Value: _this.fun8.D7, Code: "D7" })
                            vlist.push({ Key: "D9", Value: _this.fun8.D9, Code: "D9" })
                            vlist.push({ Key: "D12", Value: _this.fun8.D12, Code: "D12" })
                            vlist.push({ Key: "J30", Value: _this.fun8.J30, Code: "J30" })
                            //案例二
                            vlist.push({ Key: "E5", Value: _this.fun8.E5, Code: "E5" })
                            vlist.push({ Key: "E7", Value: _this.fun8.E7, Code: "E7" })
                            vlist.push({ Key: "E9", Value: _this.fun8.E9, Code: "E9" })
                            vlist.push({ Key: "E12", Value: _this.fun8.E12, Code: "E12" })
                            vlist.push({ Key: "K30", Value: _this.fun8.K30, Code: "K30" })
                            //案例三
                            vlist.push({ Key: "F5", Value: _this.fun8.F5, Code: "F5" })
                            vlist.push({ Key: "F7", Value: _this.fun8.F7, Code: "F7" })
                            vlist.push({ Key: "F9", Value: _this.fun8.F9, Code: "F9" })
                            vlist.push({ Key: "F12", Value: _this.fun8.F12, Code: "F12" })
                            vlist.push({ Key: "L30", Value: _this.fun8.L30, Code: "L30" })

                            vlist.push({ Key: "Name1", Value: _this.fun8.Name1, Code: "Name1" })
                            vlist.push({ Key: "Name2", Value: _this.fun8.Name2, Code: "Name2" })
                            vlist.push({ Key: "Name3", Value: _this.fun8.Name3, Code: "Name3" })

                            var v = { id: item, value: vlist }
                            computevalue.push(v)
                        }
                        //工业土地市场法
                        if (item == 9) {
                            var vlist = [];
                            vlist.push({ Key: "C5", value: _this.fun9.C5, Code: "C5" })
                            vlist.push({ Key: "C7", Value: _this.fun9.C7, Code: "C7" })
                            vlist.push({ Key: "B16", Value: _this.fun9.B16, Code: "B16" })
                            //案例一
                            vlist.push({ Key: "D5", Value: _this.fun9.D5, Code: "D5" })
                            vlist.push({ Key: "D7", Value: _this.fun9.D7, Code: "D7" })
                            vlist.push({ Key: "D12", Value: _this.fun9.D12, Code: "D12" })
                            vlist.push({ Key: "J30", Value: _this.fun9.J30, Code: "J30" })
                            vlist.push({ Key: "D9", Value: _this.fun9.D9, Code: "D9" })
                            //案例二
                            vlist.push({ Key: "E5", Value: _this.fun9.E5, Code: "E5" })
                            vlist.push({ Key: "E7", Value: _this.fun9.E7, Code: "E7" })
                            vlist.push({ Key: "E12", Value: _this.fun9.E12, Code: "E12" })
                            vlist.push({ Key: "K30", Value: _this.fun9.K30, Code: "K30" })
                            vlist.push({ Key: "E9", Value: _this.fun9.E9, Code: "E9" })
                            //案例三
                            vlist.push({ Key: "F5", Value: _this.fun9.F5, Code: "F5" })
                            vlist.push({ Key: "F7", Value: _this.fun9.F7, Code: "F7" })
                            vlist.push({ Key: "F12", Value: _this.fun9.F12, Code: "F12" })
                            vlist.push({ Key: "L30", Value: _this.fun9.L30, Code: "L30" })
                            vlist.push({ Key: "F9", Value: _this.fun9.F9, Code: "F9" })

                            vlist.push({ Key: "Name1", Value: _this.fun9.Name1, Code: "Name1" })
                            vlist.push({ Key: "Name2", Value: _this.fun9.Name2, Code: "Name2" })
                            vlist.push({ Key: "Name3", Value: _this.fun9.Name3, Code: "Name3" })

                            var v = { id: item, value: vlist }
                            computevalue.push(v)
                        }
                        //土地剩余法
                        if (item == 10) {
                            var vlist = [];
                            vlist.push({ Key: "B3", Value: _this.fun10.B3, Code: "B3" })
                            vlist.push({ Key: "B4", Value: _this.fun10.B4, Code: "B4" })
                            vlist.push({ Key: "B5", Value: _this.fun10.B5, Code: "B5" })
                            vlist.push({ Key: "B8", Value: _this.fun10.B8, Code: "B8" })
                            vlist.push({ Key: "B21", Value: _this.fun10.B21, Code: "B21" })
                            vlist.push({ Key: "B22", Value: _this.fun10.B22, Code: "B22" })
                            vlist.push({ Key: "B23", Value: _this.fun10.B23, Code: "B23" })
                            vlist.push({ Key: "B24", Value: _this.fun10.B24, Code: "B24" })
                            vlist.push({ Key: "B25", Value: _this.fun10.B25, Code: "B25" })
                            vlist.push({ Key: "B26", Value: _this.fun10.B26, Code: "B26" })
                            vlist.push({ Key: "B27", Value: _this.fun10.B27, Code: "B27" })
                            vlist.push({ Key: "B28", Value: _this.fun10.B28, Code: "B28" })
                            vlist.push({ Key: "B29", Value: _this.fun10.B29, Code: "B29" })
                            vlist.push({ Key: "F4", Value: _this.fun10.F4, Code: "F4" })
                            if (_this.fun10.H3 == '是') {
                                vlist.push({ Key: "F7", Value: _this.fun10.F7, Code: "F7" })
                                vlist.push({ Key: "F8", Value: _this.fun10.F8, Code: "F8" })
                                vlist.push({ Key: "F9", Value: _this.fun10.F9, Code: "F9" })
                                vlist.push({ Key: "F10", Value: _this.fun10.F10, Code: "F10" })
                                vlist.push({ Key: "F11", Value: _this.fun10.F11, Code: "F11" })
                                vlist.push({ Key: "F12", Value: _this.fun10.F12, Code: "F12" })
                            } else {
                                vlist.push({ Key: "H7", Value: _this.fun10.H7, Code: "H7" })
                                vlist.push({ Key: "H9", Value: _this.fun10.H9, Code: "H9" })
                                vlist.push({ Key: "H10", Value: _this.fun10.H10, Code: "H10" })
                                vlist.push({ Key: "H11", Value: _this.fun10.H11, Code: "H11" })
                                vlist.push({ Key: "H12", Value: _this.fun10.H12, Code: "H12" })
                            }



                            var v = { id: item, value: vlist }
                            computevalue.push(v)
                        }
                    });

                    var flasstr = "";
                    if (_this.flawlist.length > 0) {
                        flasstr = _this.flawlist.join(",");
                    }
                    var ref = {
                        AssetId: _this.rowAssetInfo.Id,
                        Name: _this.ziform.name,
                        Address: _this.ziform.dizhi,
                        Lat: _this.asslat,
                        Long: _this.asslng,
                        ComputeTypeIds: tids,
                        DisposeTypeId: dtid,
                        Realization: _this.ziform2.discount,
                        TypeValue: typevalue,
                        ComputeValue: computevalue,
                        ComputeArea: _this.ziform2.area,
                        IsOtherAsset: false,
                        ReducedPrice: _this.ziform.ReducedPrice,
                        NewReducedPrice: _this.ziform.NewReducedPrice,
                        BuyAssessPrice: _this.ziform.BuyAssessPrice,
                        Flaw: flasstr,
                        Remark: _this.ziform.Remark,
                        NewRemark: _this.ziform.NewRemark,
                        DisposalPriceRemark: _this.ziform.DisposalPriceRemark,
                        AreaCode:_this.areacode
                    }
                    if (_this.qtzcshow == true) {
                        ref = {
                            AssetId: _this.rowAssetInfo.Id,
                            Name: _this.ziform.name,
                            Address: _this.ziform.dizhi,
                            Lat: _this.asslat,
                            Long: _this.asslng,
                            ComputeTypeIds: tids,
                            DisposeTypeId: dtid,
                            Realization: _this.ziform2.discount,
                            TypeValue: typevalue,
                            ComputeValue: computevalue,
                            ComputeArea: _this.ziform2.area,

                            IsOtherAsset: true,
                            ReducedPrice: _this.ziform.ReducedPrice,
                            BuyAssessPrice: _this.ziform.BuyAssessPrice,
                            ComputePrice: _this.ziform.ComputePrice,
                            RealizableValue: _this.ziform.RealizableValue,
                            LiquidateValue: _this.ziform.LiquidateValue,
                            RealizableTax: _this.ziform.RealizableTax,

                            Flaw: flasstr,
                            Remark: _this.ziform.Remark,
                            NewRemark: _this.ziform.NewRemark,
                            DisposalPriceRemark: _this.ziform.DisposalPriceRemark,
                            AreaCode:_this.areacode
                        }
                    }
                    if (_this.clshow == false) {
                        ref.DisposeTypeId = 0;
                        ref.ComputeTypeIds = ""
                    } 
                    if (type == 1) {
                        editAssetCompute(ref).then((res) => {
                            if (res.data.success) {
                                _this.editLoading = false;
                                _this.$message({
                                    message: res.data.msg,
                                    type: "success",
                                });
                            } else {
                                _this.$message({
                                    message: res.data.msg,
                                    type: "error",
                                });
                            }
                        });
                    } else {
                        AssetComputeApi(ref).then((res) => {
                            if (res.data.success) {
                                var strblprice = '';
                                if (baselandis == 1) {
                                    strblprice = "基准地价价格：" + res.data.response.BaseLandPrice + "元/㎡（住宅）；" + res.data.response.BaseLandPrice2 + "元/㎡(商业)。"
                                }
                                if (baselandis == 2) {
                                    strblprice = "基准地价价格：" + res.data.response.BaseLandPrice + "元/㎡。"
                                }

                                _this.resPrice = res.data.response.PirceMsg != null ? res.data.response.PirceMsg : '' + strblprice + "评估单价：" + res.data.response.UnitPrice + "元/㎡；评估总价：" + res.data.response.TotalPrice + "万元"

                                _this.editLoading = false;
                                _this.$message({
                                    message: res.data.msg,
                                    type: "success",
                                });
                                _this.getAssetLogs(_this.rowAssetInfo.Id)
                            } else {
                                _this.$message({
                                    message: res.data.msg,
                                    type: "error",
                                });
                            }
                        });
                    }
                }).catch(function () {
                    return;
                });
            });
        },
        //删除
        handleDelete(index, row) {
            this.$confirm('您确定删除编号为：' + row.Code + '的资产吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    instance.delete('/AssetsInfo/Delete?id=' + row.Id).then(res => {
                        if (res.data.success) {
                            this.tableData.splice(index, 1);
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            //操作日志
                            let param = { ExternalId: res.data.response, Name: row.Name + "-删除" };
                            addSysOperateLog(param);
                        } else {
                            this.$message({
                                type: 'info',
                                message: res.data.msg
                            });
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
        },
        //分页
        //当前加载页 改变时会触发
        handleCurrentChange(val) {
            this.paging.currentPage = val;
            this.getAssetsInfoList();
        },

        //新建资产
        newbuild() {
            this.assettctitle = '新建资产'
            this.newform.owner = '';
            this.newform.CustomersName = "";
            this.newform.name = "";
            this.newform.type = '';
            this.newform.mortgage = ''
            this.newform.area = "0"
            this.newform.area2 = "0",

                this.newform.liabilities = "负债或或有负债";
            this.newform.liabilitiesPrice = 0;
            this.newform.compensationRatio = 0;
            this.newform.floor = 0;
            this.newform.id = 0;

            this.dialogFormVisible = true
            this.liashow = false;
            this.liashow2 = false;
        },
        cancel() {
            this.dialogFormVisible = false
        },
        edithandleClick(row, index) {
            this.liashow = false;
            this.liashow2 = false;
            this.assettctitle = '编辑资产'
            this.editassetinfo = row;
            this.newform.id = row.Id;
            console.log(this.ownerlist)
            if(row.CustomersId!=null&&row.CustomersId.length>0){
                this.newform.owner = row.CustomersId.split("|").map(str => parseInt(str));;
            }
            console.log(this.newform.owner)
            this.newform.CustomersName = row.CustomersName;
            this.newform.name = row.Name
            this.newform.type = row.TypeId
            this.newform.mortgage = row.ComputeMode
            this.newform.area = row.ComputeArea
            this.newform.area2 = row.ComputeArea2
            this.newform.liabilities = row.Liabilities
            this.newform.liabilitiesPrice = row.LiabilitiesPrice
            this.newform.compensationRatio = row.CompensationRatio
            this.newform.floor = row.Floor;

            this.changeAssettype(row.TypeId)
            this.changemortgage(row.ComputeMode)
            if (row.ComputeMode == 3) {
                this.changeLiabilities(row.liabilities)
            }

            this.dialogFormVisible = true
        },
        determine(newform) {
            this.$refs[newform].validate((valid) => {
                if (valid) {
                    this.$confirm("确认提交吗？", "提示", {}).then(() => {
                        this.addLoading = true;
                        if (this.newform.id > 0) { //修改 
                            if(this.newform.owner!=null &&this.newform.owner.length>0){
                                this.editassetinfo.CustomersId = this.newform.owner.join('|'); 
                            }else{
                                this.editassetinfo.CustomersId ="";
                            }
                            this.editassetinfo.Name = this.newform.name;
                            this.editassetinfo.TypeId = this.newform.type;
                            this.editassetinfo.ComputeMode = this.newform.mortgage;
                            this.editassetinfo.ComputeArea = this.newform.area;
                            this.editassetinfo.ComputeArea2 = this.newform.area2;
                            this.editassetinfo.Liabilities = this.newform.liabilities;
                            this.editassetinfo.LiabilitiesPrice = this.newform.liabilitiesPrice;
                            this.editassetinfo.CompensationRatio = this.newform.compensationRatio;
                            this.editassetinfo.CustomersName = this.newform.CustomersName;
                            this.editassetinfo.Floor = this.newform.floor;

                            editAssetsInfo(this.editassetinfo).then((res) => {
                                if (res.data.success) {
                                    this.$message({
                                        message: res.data.msg,
                                        type: "success",
                                    });
                                    this.addLoading = false;
                                    this.dialogFormVisible = false

                                    this.getAssetsInfoList();
                                } else {
                                    this.$message({
                                        message: res.data.msg,
                                        type: "error",
                                    });
                                }
                            });
                        } else {
                            var info = {
                                AssetProjectId: this.basicdata.AssetProjectId,
                                AeestFamilyId: this.basicdata.Id,
                                CustomersId: "",
                                CustomersName: this.newform.CustomersName,
                                Name: this.newform.name,
                                TypeId: this.newform.type,
                                ComputeMode: this.newform.mortgage,
                                ComputeArea: this.newform.area,
                                ComputeArea2: this.newform.area2,
                                TotalPrice: 0,
                                UnitPrice: 0,
                                Liabilities: this.newform.liabilities,
                                LiabilitiesPrice: this.newform.liabilitiesPrice,
                                CompensationRatio: this.newform.compensationRatio,
                                Floor: this.newform.floor,
                            };
                            if(this.newform.owner!=null &&this.newform.owner.length>0){
                                info.CustomersId = this.newform.owner.join('|')
                            } 
                            addAssetsInfo(info).then((res) => {
                                if (res.data.success) {
                                    this.$message({
                                        message: res.data.msg,
                                        type: "success",
                                    });
                                    this.addLoading = false;
                                    this.dialogFormVisible = false

                                    this.getAssetsInfoList();
                                } else {
                                    this.$message({
                                        message: res.data.msg,
                                        type: "error",
                                    });
                                }
                            });
                        }

                    });


                } else {
                    this.$message.error('请正确填写信息');
                }
            });
        },
        //查询
        onSubmit() {

        },
        //基础数据区的功能
        edit() {
            this.editstate = true
        },
        cancelbj() {
            this.editstate = false;
            this.getAeestFamilydetail(this.$route.params.id);//获取债权户详情
        },
        //编辑
        preservation(basicdata) {
            this.editstate = false
            this.guaranteelist.forEach(i => {
                if (this.basicdata.GuaranteeType == i.label) {
                    this.basicdata.GuaranteeType = i.value
                }
            });
            this.judiciallist.forEach(i => {
                if (this.basicdata.Status == i.label) {
                    this.basicdata.Status = i.value
                }
            });
            this.pledgeAssetlist.forEach(i => {
                if (this.basicdata.PledgeAssetType == i.label) {
                    this.basicdata.PledgeAssetType = i.value
                }
            });
            this.$refs[basicdata].validate((valid) => {
                if (valid) {
                    instance.put('/AeestFamily/Put', this.basicdata).then(res => {
                        if (res.data.success) {
                            this.$message({
                                message: '编辑成功',
                                type: 'success'
                            });
                            this.getAeestFamilydetail(this.$route.params.id);//获取债权户详情
                            this.dialogFormVisible = false;
                            this.getCustomersList(this.$route.params.id);
                        } else {
                            this.$message.error('编辑错误请联系管理员');
                        }
                    });
                } else {
                    this.$message.error('请正确填写信息');
                }
            });
        },
        handanliClick(e) {
            var id = e.Id;
            this.tpInfo(id);
        },
        //土拍明细
        tpInfo(id) {
            instance.get('/Map/GetLandViewModel?id=' + id).then(res => {
                if (res.data.success) {
                    var list = res.data.response;
                    this.anlibasicdata = [
                        { name: '类别', value: '土地明细信息' },
                        { name: '宗地编号:', value: list.LandNo == null ? "/" : list.LandNo },
                        { name: '地址：', value: list.Location == null ? "/" : list.Location },
                        { name: '土地面积(m²):', value: list.LandArea == null ? "/" : list.LandArea },
                        { name: '土地面积(亩):', value: list.LandArea * 0.0015 },
                        { name: '规划容积率:', value: list.PlanningPlotRatio == null ? "/" : list.PlanningPlotRatio },
                        { name: '最大容积率：', value: list.MaxPlanningPlotRatio == null ? "/" : list.MaxPlanningPlotRatio },
                        { name: '楼面地价(元/㎡):', value: list.LandPrice2 == null ? "/" : list.LandPrice2 },
                        { name: '成交价格(万元/亩):', value: list.LandPrice == null ? "/" : list.LandPrice },
                        { name: '交易时间:', value: list.SellTime == null ? "/" : list.SellTime },
                        { name: '土地用途:', value: list.PurposeText == null ? "/" : list.PurposeText },
                        { name: '兼容用途：', value: list.CompatiblePurposeTypeID == null ? "/" : list.CompatiblePurposeTypeID },
                        { name: '规划建筑高度:', value: list.PlanningBuildingHight == null ? "/" : list.PlanningBuildingHight },
                        { name: '规划建筑密度：', value: list.PlanningBuildingCoverage == null ? "/" : list.PlanningBuildingCoverage },
                        { name: '规划建筑面积(m²):', value: list.PlanningHouseArea == null ? "/" : list.PlanningHouseArea },
                        { name: '其中规划商业建筑面积(m²):', value: list.PlaningForCommerceArea == null ? "/" : list.PlaningForCommerceArea },
                        { name: '其中规划住宅建筑面积(m²):', value: list.PlaninForResideArea == null ? "/" : list.PlaninForResideArea },
                        { name: '规划绿地率:', value: list.PlanningGreenRatio == null ? "/" : list.PlanningGreenRatio },
                        { name: '其他规划：', value: list.OtherPlanning == null ? "/" : list.OtherPlanning },
                        { name: '投资强度:', value: list.PlanningInvestment == null ? "/" : list.PlanningInvestment },
                        { name: '土地详细用途:', value: list.OtherPlanning == null ? "/" : list.OtherPlanning },
                        { name: '持证准用面积:', value: list.AcceptanceArea == null ? "/" : list.AcceptanceArea },
                        { name: '产业要求:', value: list.IndustryPlan == null ? "/" : list.IndustryPlan },
                        { name: '是否监测范围:', value: list.InMonitor == false ? '否' : '是' },
                        { name: '基准地价级别:', value: list.Grade == null ? "/" : list.Grade },
                        { name: '监测区段:', value: list.Section == null ? "/" : list.Section }
                    ];
                    this.detailstc = true;
                }
            });

        }
    }
}
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type="number"] {
    -moz-appearance: textfield;
}
</style>
<style lang="less" scoped>
.szscfbox {
    background-color: #F4F5F9;
    border: 1px solid #E7E7EB;
    padding: 2rem 0;

    .biaotistyle {
        font-size: 1.8rem;
        font-weight: 600;
        color: #999999;
        margin-bottom: 1.4rem;
    }

    .biaoneirong:hover {
        background-color: #ffffff;
    }

    .biaotou {
        display: flex;
        list-style: none;
        width: 100%;
        padding: 1rem;
        box-sizing: border-box;
        border: 1px solid #e4e7ed;
        margin: 0;

        li {
            list-style: none;
            padding: 1rem 0.4rem;
            box-sizing: border-box;
            display: flex;
            align-items: center;
        }

        .one {
            width: 8%;
        }

        .two {
            width: 18.4%;
        }

    }

    .biaoneirong {
        display: flex;
        list-style: none;
        width: 100%;
        padding: 1rem;
        box-sizing: border-box;
        border: 1px solid #e4e7ed;
        border-top: none;
        margin: 0;

        li {
            list-style: none;
            padding: 1rem 0.4rem;
            box-sizing: border-box;
            display: flex;
            align-items: center;
        }

        .one {
            width: 8%;
        }

        .two {
            width: 18.4%;
        }
    }
}

.mapbox {
    width: 100%;
    height: 100%;
    position: relative;

    .saixuan {
        box-sizing: border-box;
        position: absolute;
        top: 1.5rem;
        left: 1.5rem;
        right: 1.5rem;
        //background-color: #ffffff;
        //box-shadow: 0 0 0.6rem #e0e0e0;
        border-radius: 0.5rem;
        //padding: 2rem;
        z-index: 999999;
        display: flex;

        .saixuan_left {
            width: 30rem;

            .input {
                width: 100%;

                i {
                    font-size: 1.6rem;
                }

                i:hover {
                    color: #409EFF;
                    cursor: pointer;
                }
            }

            .zhanwei {
                width: 100%;
                display: flex;
                justify-content: center;

                .sanjiao {
                    height: 0;
                    width: 0;
                    border-width: 1rem 1rem;
                    border-style: solid;
                    border-color: transparent transparent #ffffff;
                    ;
                    display: inline-block;
                    margin-top: -0.5rem;
                }
            }

            .xiabox {
                background-color: #ffffff;
                box-shadow: 0 0 0.6rem #a1a1a1;
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    list-style: none;
                    padding: 1.2rem;
                    cursor: pointer;
                }

                li:hover {
                    background-color: #F2F6FC;
                }
            }
        }

        .saixuan_right {
            margin-left: 2rem;
        }
    }
}

.mianbox {
    padding: 1.5rem;
    background-color: #f6f8f9;
    height: 100%;
    box-sizing: border-box;

    .topbox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 2rem;

        .topbox_left {
            font-size: 2rem;
            font-weight: 600;
            color: #333333;

            .el-icon-d-arrow-left {
                cursor: pointer;
                padding-right: 1rem;
            }
        }

        .topbox_center {
            font-size: 1.4rem;
            color: #999999;
        }
    }

    .Basicinforbox {
        padding: 2rem;
        background-color: #ffffff;
        box-shadow: 0 0 0.6rem #e0e0e0;
        border-radius: 0.5rem;
        margin-top: 1.5rem;
    }

    .resultbox {
        padding: 3rem 2rem 5rem 2rem;

        .onebox {
            font-size: 2rem;
            font-weight: 600;
        }

        .twobox {
            margin-top: 2rem;
            font-size: 1.8rem;
            font-weight: 600;
        }

        .threebox {
            margin-top: 2rem;
        }

        .fourbox {
            margin-top: 2rem;
            font-weight: 600;
            font-size: 2rem;
            display: flex;

            .four_one {
                margin-right: 16rem;
            }
        }
    }

    .xinjianbox {
        padding: 2rem;
        background-color: #ffffff;
        box-shadow: 0 0 0.6rem #e0e0e0;
        border-radius: 0.5rem;
        margin-bottom: 2rem;

        .xtopbox {
            display: flex;
            justify-content: space-between;
        }


        // .xbodybox{

        // }
        .demo-drawer__content {
            padding: 0rem 0rem 7rem 0rem;

            .tczichanbox {
                padding: 0rem 2rem;
            }

            .resultaerebox {
                margin: 2rem 2rem 10rem 2rem;
                padding: 2rem;
                box-sizing: border-box;
                background-color: #F4F5F9;
                border: 1px solid #E7E7EB;

                .biaotibox {
                    font-size: 1.6rem;
                    font-weight: 600;
                    color: #666666;
                    padding-bottom: 3rem;
                }
            }

            .demo-drawer__footer {
                position: absolute;
                width: 100%;
                padding: 2rem;
                box-sizing: border-box;
                bottom: 0;
                left: 0;
                background-color: #fff;
                border-top: 1px solid #DCDFE6;
                display: flex;
                justify-content: space-around;
                z-index: 10000;
            }

        }

    }

}
</style>